import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Navbar from '../navbar';


function Insideteam_login(){

       
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");


 // Access value associated with the Username
//const User=sessionStorage.getItem("username00");

//saving default values with usestate
const state = useState();

  //username
  const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
   //password
  const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};  const[password_er,setpassword_er]=useState();

 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();
    
  //Redirecting to Services Portal if LoggedIn or Sessions Set
  //useEffect(() => { if((logstatus=="true")&&(logid>0)&&(token!=" ")){       window.location.replace("/businesstools-businesses");   } });



  /***************** Team LOGIN FUNCTION START  ******************/
  function teamlogin(){



  //Setting Error Variables
  setusername_er(" ");
  setpassword_er(" ");
  seterror_msg(" ");

   
  //Fetching Form Data
    let noerror = 0;let enoerror = 0; let $username =""; let $email="";
    $username = document.getElementById("username").value; if($username==""){ seterror_msg("Username is required !");noerror = noerror+1; }//Fetching Username Value
    const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1;enoerror = enoerror+1; }//Fetching Password Value
   
  //No primary errors
if((noerror==0)||(enoerror==0)){
    //Sending Data through axios
    axios.post("bt-inside-salesteam-login", { 
     Username:$username,
     Password:$password
   
})

   .then((response) => {


     //Validation Errors
   if(response.data.validatorerror){
     setusername_er(response.data.validatorerror.Username);
     setpassword_er(response.data.validatorerror.Password);
   
   }
   //Invalid Entries
   if(response.data.error==102){ seterror_msg("Invalid Username or Password!");  }
   if(response.data.error==106){ seterror_msg("Cannot Login, Try Again!"); }

   //Success
   if(response.data.successvar==1){

    //Setting Sessions 
    sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
    sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
    sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
    sessionStorage.setItem("logType00", "insideadmin"); //Assign value to logtype
    sessionStorage.setItem("logName00", response.data.name); //Assign value to token

   //alert("User Logged In Successfully!");
   //  window.location.replace("/candidatedetails");
   //window.location.replace("/businesstools-startworking");
   document.getElementById("success-card").style.display="block";


   
   }
   //failure
   else{
   seterror_msg("Invalid Username or Password!");

   
   }
 }    );

}
//Return Errors
else{

  

 return;
}

}
  /***************** ADMIN LOGIN FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

        {/*}  <Navbar/> */}

          <div class="main-panel">
          <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Login Successful!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/businesstools-insideteam-profile"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

              </div>
          <div class="content-wrapper pb-0">
            <div class="row d-flex justify-content-center">
              <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
              <div class="card">
                  <div class="card-body">
                 {/*} <div style={{backgroundColor:"#1b5ece",textAlign:"center",borderRadius:"5px"}}>
                    <h2 class="card-title" style={{color:"#ffffff",padding:"1rem",fontSize:"1.2rem"}}>Login to Startup Connect</h2>
                    </div> */}
                    <div style={{textAlign:"center",borderRadius:"5px", borderBottom:"0.2rem solid #1b5ece"}}>
                    <h2 class="card-title" style={{padding:"1rem",fontSize:"1.2rem"}}>TEAM LOGIN</h2>
                    </div>
                    <p  style={{textAlign:"center"}}><code>{error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Username</label>
                        <input type="text" class="form-control" id="username" placeholder="Username" value={username} onChange={updateusername} />
                        <code>{username_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Password" value={password} onChange={updatepassword} />
                        <code>{password_er}</code>
                      </div>
                      <div class="form-check form-check-flat form-check-primary">
                        {/*<label class="form-check-label">
                          <input type="checkbox" class="form-check-input" /> Remember me </label> */}
                      </div>
                      <button type="submit" id="login-btn" class="btn-lg btn-primary me-2" onClick={teamlogin}>Login</button>
                      <br/>
                   
                    </div>
                   </div>
              </div>
              </div>

            {/*}  <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
             <Userlogin />
              </div> */}

             </div>
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Insideteam_login;