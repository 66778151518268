import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


//import '../Resources/dashboard.css';

import Navbar from './navbar';
import Sidebar from './sidebar';
import Sidebar1 from './sidebar1';

function Adminbttransactions(){


  

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>ADMIN DASHBOARD</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">


     
   <Sidebar1/>  
    <div className="container-fluid page-body-wrapper">
    <Navbar />

        {/*} partial */}

        <div className="main-panel">
          
        
     
          <div class="content-wrapper pb-0">
            <div class="page-header flex-wrap">
              <div class="header-left">
            {/*}    <button class="btn btn-primary mb-2 mb-md-0 me-2"> Create new document </button>
                <button class="btn btn-outline-primary bg-white mb-2 mb-md-0"> Import documents </button> */}
              </div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Business Tools Transactions | Admin Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0">ADE-00234</p>
                  </a>
                </div>
            
              </div>
              </div>

              <div>


                {/* FEEDBACKS TABLE START */}
                <div class="col-lg-12 grid-margin stretch-card">

               


                <div class="card">

                <div class="template-demo d-flex justify-content-between mt-3">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>
                         {/*}   <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>

                              </div>*/}
                              </div>
                               </div>

                <div class="card-body">
                    <h4 class="card-title">Transactions</h4>
                    <p class="card-description">transaction history
                    </p>
                      <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                       <tr >
                            <th  style={{fontWeight:"bold"}}>Transaction Id</th>
                            <th  style={{fontWeight:"bold"}}>User Id</th>         
                            <th  style={{fontWeight:"bold"}}>Admin Id</th>
                            <th  style={{fontWeight:"bold"}}>Busy Id</th>
                            <th  style={{fontWeight:"bold"}}>Counter Id</th>
                            <th  style={{fontWeight:"bold"}}>Account Type</th>
                            <th  style={{fontWeight:"bold"}}>Tr_no</th>
                            <th  style={{fontWeight:"bold"}}>Particular</th>
                            <th  style={{fontWeight:"bold"}}>Transactions</th>
                            <th  style={{fontWeight:"bold"}}>Tleft</th>
                            <th  style={{fontWeight:"bold"}}>Status</th>
                            <th  style={{fontWeight:"bold"}}>Admin</th>
                            <th  style={{fontWeight:"bold"}}>Remarks</th>
                            <th  style={{fontWeight:"bold"}}>Created At</th>
                            <th  style={{fontWeight:"bold"}}>Updated At</th>
                            <th  style={{fontWeight:"bold"}}></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>12345</td>
                            <td>123</td>
                            <td>123</td>
                            <td>421</td>
                            <td>838</td>
                            <td>admin</td>
                            <td>415</td>
                            <td>This is purchase entry</td>
                            <td>200</td>
                            <td>45</td>
                            <td><label class="badge badge-success">active</label></td>
                            <td>customer details not found</td>
                            <td>ABC Enterprises</td>
                            <td>2024-01-08 <br/> <span class="mt-2">(15:13pm)</span></td>
                            <td>2024-01-08 <br/>  <span class="mt-2">(15:13pm)</span></td>
                            <td><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{
                                 document.getElementById("edit-form").style.display="block";
                            }} ></i></td>
                        </tr>
                        </tbody>
                        </table>
                        </div>
                        </div>
                        </div>
                        </div>
                    {/* ADMIN TABLE END */}

            </div>
            </div>


          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Adminbttransactions;