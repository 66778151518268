import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



import '../Resources/dashboard.css';


function Insideadmin_createsalescoupon(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");



 
//saving default values with usestate
const state = useState();


//coupon_code
const[coupon_code,setcoupon_code]=useState();const updatecoupon_code = (event) => { setcoupon_code(event.target.value);}; const[coupon_code_er,setcoupon_code_er]=useState();
//steam_id
const[steam_id,setsteam_id]=useState();const updatesteam_id = (event) => { setsteam_id(event.target.value);}; const[steam_id_er,setsteam_id_er]=useState();
//team_id
const[team_id,setteam_id]=useState();const updateteam_id = (event) => { setteam_id(event.target.value);}; const[team_id_er,setteam_id_er]=useState();
//username
const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
//coupon_status
const[coupon_status,setcoupon_status]=useState();const updatecoupon_status = (event) => { setcoupon_status(event.target.value);}; const[coupon_status_er,setcoupon_status_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//category
const[category,setcategory]=useState();const updatecategory = (event) => { setcategory(event.target.value);}; const[category_er,setcategory_er]=useState();
//sub_category
const[sub_category,setsub_category]=useState();const updatesub_category = (event) => { setsub_category(event.target.value);}; const[sub_category_er,setsub_category_er]=useState();
//size
const[size,setsize]=useState();const updatesize = (event) => { setsize(event.target.value);}; const[size_er,setsize_er]=useState();
//user
const[user,setuser]=useState();const updateuser = (event) => { setuser(event.target.value);}; const[user_er,setuser_er]=useState();
//valid
const[valid,setvalid]=useState();const updatevalid = (event) => { setvalid(event.target.value);}; const[valid_er,setvalid_er]=useState();
//discount_per
const[discount_per,setdiscount_per]=useState();const updatediscount_per = (event) => { setdiscount_per(event.target.value);}; const[discount_per_er,setdiscount_per_er]=useState();
//discount_amt
const[discount_amt,setdiscount_amt]=useState();const updatediscount_amt = (event) => { setdiscount_amt(event.target.value);}; const[discount_amt_er,setdiscount_amt_er]=useState();





//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD COUPON CODE FUNCTION START ********************//
function createcouponcode(){

  //Setting Error Variables
  setcoupon_code_er(" "); setsteam_id_er(" "); setteam_id_er(" "); setusername_er(" "); setcoupon_status_er(" "); settype_er(" "); setcategory_er(" "); setsub_category_er(" "); setsize_er(" "); setuser_er(); setvalid_er(); seterror_msg(" ");
  setdiscount_per_er(" "); setdiscount_amt_er(" ");
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 
  axios.post("bt-create-inside-salesteam-couponcode", {
   Log_id:logid,
   Token:token,
   Coupon_code:coupon_code,
   Steam_id:steam_id,
   Team_id:team_id,
   Username:username,
   Coupon_status:coupon_status,
   Type:type,
   Category : category,
   Sub_category:sub_category,
   Size : size,
   User:user,
   Valid:valid,
   Discount_per:discount_per,
   Discount_amt:discount_amt,
  
  })
  .then((response) => {

   

  //Customers Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      
 else if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/");
  }

   //Validation Errors
  else if(response.data.validatorerror){
  setcoupon_code_er(response.data.validatorerror.Coupon_code);
  setsteam_id_er(response.data.validatorerror.Steam_id);
  setteam_id_er(response.data.validatorerror.Team_id);
  setusername_er(response.data.validatorerror.Username);
  setcoupon_status_er(response.data.validatorerror.Coupon_status);
  setsize_er(response.data.validatorerror.Size);
  setuser_er(response.data.validatorerror.User);
  setvalid_er(response.data.validatorerror.Valid);
  settype_er(response.data.validatorerror.Type);
  setcategory_er(response.data.validatorerror.Category);
  setsub_category_er(response.data.validatorerror.Sub_category);
  setdiscount_per_er(response.data.validatorerror.Discount_per);
  setdiscount_amt_er(response.data.validatorerror.Discount_amt);
 
 }
//Invalid Entries
else if(response.data.error==102){ 
  
  setcoupon_code_er(response.data.coupon_code_er);
  setsteam_id_er(response.data.steam_id_er);
  setteam_id_er(response.data.team_id_er);
  setusername_er(response.data.username_er);
  setcoupon_status_er(response.data.coupon_status_er);
  setsize_er(response.data.size_er);
  setuser_er(response.data.user_er);
  setvalid_er(response.data.valid_er);
  settype_er(response.data.type_er);
  setcategory_er(response.data.category_er);
  setsub_category_er(response.data.sub_category_er);
  setdiscount_per_er(response.data.discount_per_er);
  setdiscount_amt_er(response.data.discount_amt_er);
  seterror_msg("Cannot Add COUPON CODE!");  }

 //Email Already Exists
 else if(response.data.error==103){  seterror_msg(response.data.error_msg); } 

   //Transaction Entered Successfully
  else if(response.data.successvar==1){  document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add COUPON CODE !"); }


  


});

}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* ADD COUPON CODE FUNCTION END ********************//





return(
    <>
                <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Create New Coupon Coder</h4>
                    <div class="form-sample">
                      <p class="card-description">new coupon code</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Code<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="CODE1234" id="coupon_code" value={coupon_code} onChange={updatecoupon_code} />
                              <code>{coupon_code_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Status</label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="active" id="coupon_status" value={coupon_status} onChange={updatecoupon_status} />
                              <code>{coupon_status_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="STEAM123" id="steam_id" value={steam_id} onChange={updatesteam_id} />
                              <code>{steam_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="TEAM1234" id="team_id" value={team_id} onChange={updateteam_id} />
                              <code>{team_id_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="MYUSER123" id="username" value={username} onChange={updateusername} />
                              <code>{username_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Valid<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="Suman Kumar" id="valid" value={valid} onChange={updatevalid} />
                              <code>{valid_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount %<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="20.00" id="discount_per" value={discount_per} onChange={updatediscount_per} />
                              <code>{discount_per_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Discount Amt<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="180.00" id="discount_amt" value={discount_amt} onChange={updatediscount_amt} />
                              <code>{discount_amt_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    <div class="row">
                   {/*}     <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">User</label>
                            <div class="col-sm-9">
                              <input type="User" class="form-control" placeholder="0" id="user" value={user} onChange={updateuser} />
                              <code>{user_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Size</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="1000" id="size" value={size} onChange={updatesize} />
                              <code>{size_er}</code>
                            </div>
                          </div>
                        </div>
                        </div> 
                   
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Inside" id="category" value={category} onChange={updatecategory} />
                              <code>{category_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sub Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="OHHS"  id="sub_category" value={sub_category} onChange={updatesub_category}/>
                              <code>{sub_category_er}</code>
                            </div>
                          </div>
                        </div>
                    
                      </div>
                    
                     
                       
                     
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={createcouponcode}>Add Sales Coupon</button>
                      
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Insideadmin_createsalescoupon;