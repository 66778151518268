import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Insideadmin_navbar from './insideadmin_navbar.js';
import Insideadmin_sidebar from './insideadmin_sidebar.js';
import moment from 'moment/moment';
//import Create_customer from '../create_customer';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Insideadmin_addsalesteam from './insideadmin_addsalesteam.js';



function Insideadmin_salesteam(){

  
    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "customers_data.xlsx");
};



  //console.log
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");



{/* CUSTOMERS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17

//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_steam_id,setfilter_steam_id] = useState(); const updatefilter_steam_id = (event) => { setfilter_steam_id(event.target.value);}; const[filter_steam_id_er,setfilter_steam_id_er]=useState();
const[filter_team_id,setfilter_team_id] = useState(); const updatefilter_team_id = (event) => { setfilter_team_id(event.target.value);}; const[filter_team_id_er,setfilter_team_id_er]=useState();
const[filter_username,setfilter_username] = useState(); const updatefilter_username = (event) => { setfilter_username(event.target.value);}; const[filter_username_er,setfilter_username_er]=useState();
const[filter_name,setfilter_name] = useState(); const updatefilter_name = (event) => { setfilter_name(event.target.value);}; const[filter_name_er,setfilter_name_er]=useState();
const[filter_email,setfilter_email] = useState(); const updatefilter_email = (event) => { setfilter_email(event.target.value);}; const[filter_email_er,setfilter_email_er]=useState();
const[filter_contact,setfilter_contact] = useState(); const updatefilter_contact = (event) => { setfilter_contact(event.target.value);}; const[filter_contact_er,setfilter_contact_er]=useState();
const[filter_city,setfilter_city] = useState(); const updatefilter_city = (event) => { setfilter_city(event.target.value);}; const[filter_city_er,setfilter_city_er]=useState();
const[filter_state,setfilter_state] = useState(); const updatefilter_state = (event) => { setfilter_state(event.target.value);}; const[filter_state_er,setfilter_state_er]=useState();
const[filter_country,setfilter_country] = useState(); const updatefilter_country = (event) => { setfilter_country(event.target.value);}; const[filter_country_er,setfilter_country_er]=useState();
const[filter_pincode,setfilter_pincode] = useState(); const updatefilter_pincode = (event) => { setfilter_pincode(event.target.value);}; const[filter_pincode_er,setfilter_pincode_er]=useState();
const[filter_date_of_joining,setfilter_date_of_joining] = useState(); const updatefilter_date_of_joining = (event) => { setfilter_date_of_joining(event.target.value);}; const[filter_date_of_joining_er,setfilter_date_of_joining_er]=useState();
const[filter_date_of_birth,setfilter_date_of_birth] = useState(); const updatefilter_date_of_birth = (event) => { setfilter_date_of_birth(event.target.value);}; const[filter_date_of_birth_er,setfilter_date_of_birth_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_status,setfilter_status] = useState(); const updatefilter_status = (event) => { setfilter_status(event.target.value);}; const[filter_status_er,setfilter_status_er]=useState();

//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET SALES TEAM DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 

   const $filter_steam_id = filter_steam_id; const $filter_team_id = filter_team_id; const $filter_username = filter_username; const $filter_email = filter_email; const $filter_contact = filter_contact; 
   const $filter_city = filter_city; const $filter_state = filter_state; const $filter_country = filter_country; const $filter_pincode = filter_pincode;
   const $filter_date_of_joining = filter_date_of_joining; const $filter_date_of_birth = filter_date_of_birth;const $filter_type = filter_type; const $filter_status = filter_status;
   const $filter_name = filter_name;
 axios.post("bt-get-salesteam-list", {
   Log_id:logid,
   Token:token,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Filter_val : filter_val,
   Filter_steam_id : $filter_steam_id,
   Filter_team_id : $filter_team_id,
   Filter_username : $filter_username,
   Filter_name : $filter_name,
   Filter_email : $filter_email,
   Filter_contact : $filter_contact,
   Filter_city : $filter_city,
   Filter_state : $filter_state,
   Filter_country : $filter_country,
   Filter_pincode : $filter_pincode,
   Filter_type : $filter_type,
   Filter_date_of_joining : moment($filter_date_of_joining).format('YYYY-MM-DD'),
   Filter_date_of_birth : moment($filter_date_of_birth).format('YYYY-MM-DD'),
   Filter_status : $filter_status,
   
   //Code Edits for pagination
   'page' : pageNumber


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_steam_id_er(response.data.validatorerror.Filter_steam_id);
    setfilter_team_id_er(response.data.validatorerror.Filter_team_id);
    setfilter_username_er(response.data.validatorerror.Filter_username);
    setfilter_email_er(response.data.validatorerror.Filter_email);
    setfilter_contact_er(response.data.validatorerror.Filter_contact);
    setfilter_city_er(response.data.validatorerror.Filter_city);
    setfilter_state_er(response.data.validatorerror.Filter_state);
    setfilter_country_er(response.data.validatorerror.Filter_country);
    setfilter_pincode_er(response.data.validatorerror.Filter_pincode);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_date_of_joining_er(response.data.validatorerror.Filter_date_of_joining);
    setfilter_date_of_birth_er(response.data.validatorerror.Filter_date_of_birth);
    setfilter_status_er(response.data.validatorerror.Filter_status);

  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_steam_id_er(response.data.filter_steam_id);
    setfilter_team_id_er(response.data.filter_team_id);
    setfilter_username_er(response.data.filter_username);
    setfilter_email_er(response.data.filter_email);
    setfilter_contact_er(response.data.filter_contact);
    setfilter_city_er(response.data.filter_city);
    setfilter_state_er(response.data.filter_state);
    setfilter_country_er(response.data.filter_country);
    setfilter_pincode_er(response.data.filter_pincode);
    setfilter_type_er(response.data.filter_type);
    setfilter_date_of_joining_er(response.data.filter_date_of_joining);
    setfilter_date_of_birth_er(response.data.filter_date_of_birth);
    setfilter_status_er(response.data.filter_status);

  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CUSTOMERS DATA FUNCTION END  ********************//


//esteam_id
const[esteam_id,setesteam_id]=useState();const updateesteam_id = (event) => { setesteam_id(event.target.value);}; const[esteam_id_er,setesteam_id_er]=useState();
//eteam_id
const[eteam_id,seteteam_id]=useState();const updateeteam_id = (event) => { seteteam_id(event.target.value);}; const[eteam_id_er,seteteam_id_er]=useState();
//eusername
const[eusername,seteusername]=useState();const updateeusername = (event) => { seteusername(event.target.value);}; const[eusername_er,seteusername_er]=useState();
//ename
const[ename,setename]=useState();const updateename = (event) => { setename(event.target.value);}; const[ename_er,setename_er]=useState();
//eemail
const[eemail,seteemail]=useState();const updateeemail = (event) => { seteemail(event.target.value);}; const[eemail_er,seteemail_er]=useState();
//econtact
const[econtact,setecontact]=useState();const updateecontact = (event) => { setecontact(event.target.value);}; const[econtact_er,setecontact_er]=useState();
//epassword
const[epassword,setepassword]=useState();const updateepassword = (event) => { setepassword(event.target.value);}; const[epassword_er,setepassword_er]=useState();
//ecpasssword
const[ecpassword,setecpassword]=useState();const updateecpassword = (event) => { setecpassword(event.target.value);}; const[ecpassword_er,setecpassword_er]=useState();
//ecity
const[ecity,setecity]=useState();const updateecity = (event) => { setecity(event.target.value);}; const[ecity_er,setecity_er]=useState();
//ecstate
const[ecstate,setecstate]=useState();const updateecstate = (event) => { setecstate(event.target.value);}; const[ecstate_er,setecstate_er]=useState();
//ecountry
const[ecountry,setecountry]=useState();const updateecountry = (event) => { setecountry(event.target.value);}; const[ecountry_er,setecountry_er]=useState();
//epincode
const[epincode,setepincode]=useState();const updateepincode = (event) => { setepincode(event.target.value);}; const[epincode_er,setepincode_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//edate_of_joining
const[edate_of_joining,setedate_of_joining]=useState();const updateedate_of_joining = (event) => { setedate_of_joining(event.target.value);}; const[edate_of_joining_er,setedate_of_joining_er]=useState();
//edate_of_birth
const[edate_of_birth,setedate_of_birth]=useState();const updateedate_of_birth = (event) => { setedate_of_birth(event.target.value);}; const[edate_of_birth_er,setedate_of_birth_er]=useState();
//estatus
const[estatus,setestatus]=useState();const updateestatus = (event) => { setestatus(event.target.value);}; const[estatus_er,setestatus_er]=useState();



//ERROR VARIABLES 
const[eerror_msg,seteerror_msg]=useState();
 
 
//******************* EDIT SALES TEAM DEATILS FUNCTION START ********************//
function editsalesteam(){

  const editId=sessionStorage.getItem("EditId00");
  //Setting Error Variables
  setesteam_id_er(" ");seteteam_id_er(" ");seteusername_er(" ");setepassword_er(" ");setecpassword_er(" ");seteemail_er(" ");setecontact_er(" ");setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setedate_of_joining_er();setedate_of_birth_er();setestatus_er();seteerror_msg(" ");

  const $esteam_id = document.getElementById("esteam_id").value;
  const $eteam_id = document.getElementById("eteam_id").value;
  const $eusername = document.getElementById("eusername").value;
  const $ename = document.getElementById("ename").value;
  const $epassword = document.getElementById("epassword").value;
  const $ecpassword = document.getElementById("ecpassword").value;
  const $econtact = document.getElementById("econtact").value;
  const $eemail = document.getElementById("eemail").value;
  const $ecity = document.getElementById("ecity").value;
  const $ecstate = document.getElementById("ecstate").value;
  const $ecountry = document.getElementById("ecountry").value;
  const $epincode = document.getElementById("epincode").value;
  const $etype = document.getElementById("etype").value;
  const $estatus = document.getElementById("estatus").value;
  const $edate_of_joining = document.getElementById("edate_of_joining").value;
  const $edate_of_birth = document.getElementById("edate_of_birth").value;


  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 
  axios.post("bt-edit-inside-salesteam-member", {
   Log_id:logid,
   Token:token,
   Team_id:$eteam_id,
   Steam_id:$esteam_id,
   Username : $eusername,
   Name : $ename,
   Password:$epassword,
   Confirm_password:$ecpassword,
   Contact : $econtact,
   Email:$eemail,
   City:$ecity,
   State:$ecstate,
   Country:$ecountry,
   Pincode:$epincode,
   Type:$etype,
   Status:$estatus,
   Date_of_joining:$edate_of_joining,
   Date_of_birth:$edate_of_birth,
   Edit_id:editId


  })
  .then((response) => {


  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("EditId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 else if(response.data.validatorerror){
  seteteam_id_er(response.data.validatorerror.Team_id);
  setesteam_id_er(response.data.validatorerror.Steam_id);
  seteusername_er(response.data.validatorerror.Username);
  setepassword_er(response.data.validatorerror.Password);
  setecpassword_er(response.data.validatorerror.Confirm_password);
  setename_er(response.data.validatorerror.Name);
  seteemail_er(response.data.validatorerror.Email);
  setecontact_er(response.data.validatorerror.Contact);
  setecity_er(response.data.validatorerror.City);
  setecstate_er(response.data.validatorerror.State);  
  setecountry_er(response.data.validatorerror.Country);
  setepincode_er(response.data.validatorerror.Pincode);  
  setetype_er(response.data.validatorerror.Type);
  setedate_of_birth_er(response.data.validatorerror.Date_of_birth);
  setedate_of_joining_er(response.data.validatorerror.Date_of_joining);
  setestatus_er(response.data.validatorerror.Status);

}
//Invalid Entries
else if(response.data.error==102){ 
  seteteam_id_er(response.data.team_id_er);
  setesteam_id_er(response.data.steam_id_er);
  seteusername_er(response.data.username_er);
  setename_er(response.data.name_er);
  seteemail_er(response.data.email_er);
  setecontact_er(response.data.contact_er);
  setepassword_er(response.data.password_er);
  setecpassword_er(response.data.cpassword_er);
  setecity_er(response.data.city_er);
  setecstate_er(response.data.state_er);
  setecountry_er(response.data.country_er);
  setepincode_er(response.data.pincode_er);
  setetype_er(response.data.type_er);
  setedate_of_birth_er(response.data.date_of_birth_er);
  setedate_of_joining_er(response.data.date_of_joining_er);
  setestatus_er(response.data.status_er);
 
  seteerror_msg("Cannot Edit Sales Team Member!");  }


  //Invalid Entries
  else if(response.data.error==103){     seteerror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   else if(response.data.successvar==1){        

    document.getElementById("edit-form").style.display="none";  
    setesteam_id_er(" ");seteteam_id_er(" ");seteusername_er(" ");setepassword_er(" ");setecpassword_er(" ");seteemail_er(" ");setecontact_er(" ");setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setedate_of_joining_er();setedate_of_birth_er();setestatus_er();seteerror_msg(" ");
    setesteam_id(" ");seteteam_id(" ");seteusername(" ");setepassword(" ");setecpassword(" ");seteemail(" ");setecontact(" ");setecity();setecstate();setecountry();setepincode();setetype();setedate_of_joining();setedate_of_birth();setestatus();

    
    document.getElementById("edit-success-card").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seteerror_msg("Cannot Edit Sales Team Member!"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT CUSTOMER FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Insideadmin_sidebar />
    <div class="container-fluid page-body-wrapper">




       <Insideadmin_navbar />
        {/*} partial */}
        <div class="main-panel">

          {/* ADD CUSTOMER SUCCESS CARD START */}
          <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">New Sales Team Member Added Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-insideadmin-salesteam"><button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD CUSTOMER SUCCESS CARD END */}

                  {/* ADD CUSTOMER EDIT SUCCESS CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Sales Team Member Details Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <a href="businesstools-insideadmin-salesteam"> <button class="mt-3 btn btn-outline-danger">Close</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD CUSTOMER EDIT SUCCESS CARD END */}
               {/* ADD CUSTOMER EDIT ERROR CARD START */}
        <div class="d-flex justify-content-center">
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="edit-error-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-danger">Cannot Fetch Data !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to close.</p>
                      <button class="mt-3 btn btn-outline-danger" onClick={()=>{ document.getElementById("edit-error-card").style.display="none"; }}>Close</button>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/* ADD CUSTOMER EDIT ERROR CARD END */}

          

          <div class="content-wrapper pb-0">

         {/* CREATE SALES TEAM FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
             <Insideadmin_addsalesteam />
              </div>
          </div>
          {/* CREATE SALES TEAM FORM END */}
          
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">SALES TEAM</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0"></p>
                  </a>
                </div>
                <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i class="mdi mdi-plus-circle"></i> Add New Sales Team Member </button>
              </div>
            </div>

             {/* EDIT SALES TEAM FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="edit-form" style={{display:"none"}}>
              <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ 
                        //Removing Sessions and Closing Edit Sales Team
                        setesteam_id_er(" ");seteteam_id_er(" ");seteusername_er(" ");setepassword_er(" ");setecpassword_er(" ");seteemail_er(" ");setecontact_er(" ");setecity_er();setecstate_er();setecountry_er();setepincode_er();setetype_er();setedate_of_joining_er();setedate_of_birth_er();setestatus_er();seteerror_msg(" ");
                        setesteam_id(" ");seteteam_id(" ");seteusername(" ");setepassword(" ");setecpassword(" ");seteemail(" ");setecontact(" ");setecity();setecstate();setecountry();setepincode();setetype();setedate_of_joining();setedate_of_birth();setestatus();
                        document.getElementById("edit-form").style.display="none";
                        sessionStorage.removeItem("CustomerId00");
                        
                         }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Edit Sales Team</h4>
                    <div class="form-sample">
                      <p class="card-description">edit new sales team</p>
                      <code>{eerror_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="TEAM9999" id="eteam_id" value={eteam_id} onChange={updateeteam_id} />
                              <code>{eteam_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="TEAM888" id="esteam_id" value={esteam_id} onChange={updateesteam_id} />
                              <code>{esteam_id_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="USER12345" id="eusername" value={eusername} onChange={updateeusername} />
                              <code>{eusername_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Kumar" id="ename" value={ename} onChange={updateename} />
                              <code>{ename_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="sumankumar123@gmail.com" id="eemail" value={eemail} onChange={updateeemail} />
                              <code>{eemail_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="econtact" value={econtact} onChange={updateecontact} />
                              <code>{econtact_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="ecity" value={ecity} onChange={updateecity} />
                              <code>{ecity_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="ecstate" value={ecstate} onChange={updateecstate} />
                              <code>{ecstate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India"  id="ecountry" value={ecountry} onChange={updateecountry}/>
                              <code>{ecountry_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" pincode="420110" id="epincode" value={epincode} onChange={updateepincode} />
                              <code>{epincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date_of_joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="edate_of_joining" value={edate_of_joining} onChange={updateedate_of_joining} />
                              <code>{edate_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date_of_birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="edate_of_birth" value={edate_of_birth} onChange={updateedate_of_birth} />
                              <code>{edate_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" id="etype" value={etype} onChange={updateetype} />
                              <code>{etype_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Offline" id="estatus" value={estatus} onChange={updateestatus} />
                              <code>{estatus_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="************" id="epassword" value={epassword} onChange={updateepassword} />
                              <code>{epassword_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="************" id="ecpassword" value={ecpassword} onChange={updateecpassword} />
                              <code>{ecpassword_er}</code>
                            </div>
                          </div>
                        </div>
                       
                      </div>
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={editsalesteam}>Edit Sales Team Member Details</button>
                      
                   
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* EDIT CUSTOMER FORM END */}

            {/* CUSTOMER DETAILS TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Sales Team</h4>
                    <p class="card-description">sales team details
                    </p>


                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_team_id" value={filter_team_id} onChange={updatefilter_team_id}  />
                              <code>{filter_team_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_steam_id" value={filter_steam_id} onChange={updatefilter_steam_id}  />
                              <code>{filter_steam_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_username" value={filter_username} onChange={updatefilter_username}  />
                              <code>{filter_username_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Rakesh Sharma" value={filter_name} onChange={updatefilter_name}  />
                              <code>{filter_name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="rakeshsharma123@gmail.com"  value={filter_email} onChange={updatefilter_email}  />
                              <code>{filter_email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="9414444410"value={filter_contact} onChange={updatefilter_contact}  />
                              <code>{filter_contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Ahmedabad" value={filter_city} onChange={updatefilter_city}  />
                              <code>{filter_city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Gujarat"  value={filter_state} onChange={updatefilter_state}  />
                              <code>{filter_state_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="India" value={filter_country} onChange={updatefilter_country}  />
                              <code>{filter_country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="482002" value={filter_pincode} onChange={updatefilter_pincode}  />
                              <code>{filter_pincode_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" value={filter_type} onChange={updatefilter_type}  />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Status</label>
                            <div class="col-sm-9">
                              <select class="form-control" style={{padding:"1rem"}} placeholder="Offline"  value={filter_status} onChange={updatefilter_status}>
                              <option value=" ">all</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{filter_status_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="Offline"  value={filter_date_of_joining} onChange={updatefilter_date_of_joining}  />
                              <code>{filter_date_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="Offline"  value={filter_date_of_birth} onChange={updatefilter_date_of_birth}  />
                              <code>{filter_date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                      </div>
                            <hr/>                      
                            </div>
                            
                    {/* FILTER SECTION END */}


                  <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                       
                          <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>

                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>     

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Steam Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Team Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Username</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Name</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Email</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Contact</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">City</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">State</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Country</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Pincode</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                             {/* Column 13 */}
                             <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date of Joining</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date of Birth</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                             {/* Column 15 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Steam Status</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c15_yes" onClick={()=>{setcd_col15_val(1); document.getElementById("cd_c15_yes").style.display="none"; document.getElementById("cd_c15_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c15_no" style={{ display:"none"}} onClick={()=>{ setcd_col15_val(0); document.getElementById("cd_c15_yes").style.display="block"; document.getElementById("cd_c15_no").style.display="none"; }}></i>
                              </div></div></li> 
                             {/* Column 16 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date Updated</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c16_yes" onClick={()=>{setcd_col16_val(1); document.getElementById("cd_c16_yes").style.display="none"; document.getElementById("cd_c16_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c16_no" style={{ display:"none"}} onClick={()=>{ setcd_col16_val(0); document.getElementById("cd_c16_yes").style.display="block"; document.getElementById("cd_c16_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 17 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c17_yes" onClick={()=>{setcd_col17_val(1); document.getElementById("cd_c17_yes").style.display="none"; document.getElementById("cd_c17_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c17_no" style={{ display:"none"}} onClick={()=>{ setcd_col17_val(0); document.getElementById("cd_c17_yes").style.display="block"; document.getElementById("cd_c17_no").style.display="none"; }}></i>
                              </div></div></li> 
                            
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive"  id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} id="cd_c1" hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c2" hidden={cd_col2_val==1}>Steam Id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c3" hidden={cd_col3_val==1}>Team Id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c4" hidden={cd_col4_val==1}>Username</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c5" hidden={cd_col5_val==1}>Name</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c6" hidden={cd_col6_val==1}>Email</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c7" hidden={cd_col7_val==1}>Contact</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c8" hidden={cd_col8_val==1}>City</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c9" hidden={cd_col9_val==1}>State</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c10" hidden={cd_col10_val==1}>Country</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c11" hidden={cd_col11_val==1}>Pincode</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c12" hidden={cd_col12_val==1}>Type</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c13" hidden={cd_col13_val==1}>Date_of_joining</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c14" hidden={cd_col14_val==1}>Date_of_birth</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c15" hidden={cd_col15_val==1}>Steam_status</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c16" hidden={cd_col16_val==1}>Last Updated</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c17" hidden={cd_col17_val==1}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                       

                        <tr key={curElm.Sn}>
                            <td id="cd_co1"  hidden={cd_col1_val==1}>{curElm.Sn}</td>
                            <td id="cd_co2"  hidden={cd_col2_val==1}>{curElm.Steam_id}</td>
                            <td id="cd_co3"  hidden={cd_col3_val==1}>{curElm.Team_id}</td>
                            <td id="cd_co4"  hidden={cd_col4_val==1}>{curElm.Username}</td>
                            <td id="cd_co5"  hidden={cd_col5_val==1}>{curElm.Name}</td>
                            <td id="cd_co6"  hidden={cd_col6_val==1}>{curElm.Email}</td>
                            <td id="cd_co7"  hidden={cd_col7_val==1}>{curElm.Contact}</td>
                            <td id="cd_co8"  hidden={cd_col8_val==1}>{curElm.City}</td>
                            <td id="cd_co9"  hidden={cd_col9_val==1}>{curElm.State}</td>
                            <td id="cd_co10"  hidden={cd_col10_val==1}>{curElm.Country}</td>
                            <td id="cd_co11"  hidden={cd_col11_val==1}>{curElm.Pincode}</td>
                            <td id="cd_co12"  hidden={cd_col12_val==1}>{curElm.Type}</td>
                            <td id="cd_co13"  hidden={cd_col13_val==1}>{curElm.Date_of_joining}</td>
                            <td id="cd_co14"  hidden={cd_col14_val==1}>{curElm.Date_of_birth}</td>
                            <td id="cd_co15"  hidden={cd_col15_val==1}>
                            <label className="badge badge-danger" hidden={curElm.Steam_status=="active"}>inactive</label>
                            <label className="badge badge-success" hidden={curElm.Steam_status=="inactive"}>active</label>
                            </td>
                            <td id="cd_co16"  hidden={cd_col16_val==1}>{curElm.Date_updated}</td>
                            <td id="cd_co17"  hidden={cd_col17_val==1}><i class="mdi mdi-pencil-box text-warning" style={{fontSize:"1.4rem"}} onClick={()=>{ 
                            //Setting Customer Id and Redirect to Edit
                            sessionStorage.setItem("EditId00",curElm.Sn);
                            //Set Data for the Edit Fields
                            document.getElementById("esteam_id").value=curElm.Steam_id;//steam_id
                            document.getElementById("eteam_id").value=curElm.Team_id;//team_id
                            document.getElementById("eusername").value=curElm.Username;//username
                            document.getElementById("ename").value=curElm.Name;//name
                            document.getElementById("econtact").value=curElm.Contact;//contact
                            document.getElementById("eemail").value=curElm.Email;//email
                            document.getElementById("ecity").value=curElm.City;//city
                            document.getElementById("ecstate").value=curElm.State;//cstate
                            document.getElementById("ecountry").value=curElm.Country;//country
                            document.getElementById("epincode").value=curElm.Pincode;//pincode
                            document.getElementById("edate_of_joining").value=curElm.Date_of_joining;//date_of_joining
                            document.getElementById("edate_of_birth").value=curElm.Date_of_birth;//date_of_birth
                            document.getElementById("etype").value=curElm.Type;//type
                            document.getElementById("estatus").value=curElm.Steam_status;//status
                            
                            document.getElementById('edit-form').style.display = 'block'; }}></i></td>
                           </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                         
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                   
                   
                  </div>
                </div>
              </div>
              {/* CUSTOMER DETAILS TABLE END */}

           {/*}   <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Customer Summary</h4>
                    <p class="card-description">customer summary
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Customer Id</th>
                            <th  style={{fontWeight:"bold"}}>Customer Name</th>
                            <th  style={{fontWeight:"bold"}}>Sales</th>
                            <th  style={{fontWeight:"bold"}}>This month</th>
                            <th  style={{fontWeight:"bold"}}>Last Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>*/}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Insideadmin_salesteam;