import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';

   // Access value associated with the login status
   const logstatus=sessionStorage.getItem("logStatus00");
   const logid=sessionStorage.getItem("logId00");
   const token=sessionStorage.getItem("logToken00");
   const logname=sessionStorage.getItem("logName00");
   const Network_val=sessionStorage.getItem("NetworkVal00");

function Navbar(){

    //ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
const[errorvar,seterrorvar]=useState();
 
const[nav_startup_list,setnav_startup_list]=useState([],);
const[nav_mentor_id,setnav_mentor_id]=useState();
const[nav_mentor_key,setnav_mentor_key]=useState();
const[nav_error,setnav_error]=useState();
const[nav_mentor_error,setnav_mentor_error]=useState(0);
const[nav_startup_error,setnav_startup_error]=useState(0);

  useEffect(()=>{
   
    getNavbarData();
    
    },[])
    

//******************* GET NAVBAR DATA FUNCTION START  ********************//
function getNavbarData(){

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((Network_val!=null)||(Network_val!=" "))){
 
 axios.post("network-navbar-default", {
   Log_id:logid,
   Token:token,
   NetworkVal:Network_val,
   })
 .then((response) => {



  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/networking-login");
  }


  //Startup Data Not Found
  if((response.data.error)==222){
    window.location.replace("/networking-startup-register");
   }
 
  //Success Data Fetched
  if(response.data.successvar==1){

 
  /* if(nav_er==111){
   
    window.location.replace("/networking-mentor-register");
  }*/


   setnav_startup_list(response.data.startup_data);


   
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    seterrorvar("Cannot Fetch Data !");

  }

  //Data Violation
  if(response.data.error==102){ 
  
  seterrorvar("Cannot Fetch Data !");


  }

    //Data Violation
    if(response.data.error==103){ 
  
      window.location.replace("/networking-login");

    }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("StartupId00");
   sessionStorage.removeItem("StartupKey00");
   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET NAVBAR DATA FUNCTION END  ********************//




return(
    <>
        {/* partial */}
        {/* partial:partials/_navbar.html */}
        <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div class="navbar-menu-wrapper d-flex align-items-stretch">
            <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
              <span class="mdi mdi-chevron-double-left"></span>
            </button>
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a class="navbar-brand brand-logo-mini" href="index.html"><img src="panel/assets/images/logo-mini.svg" alt="logo" /></a>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                {/*}  <i class="mdi mdi-email-outline"></i> */}  <i class="mdi mdi-account-circle" style={{fontSize:"2rem"}}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="messageDropdown" style={{border:"0.2rem solid #1c4ecb"}}>
                  <h6 class="p-3 mb-0 font-weight-semibold">My Profile</h6>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item">
                  {/*} <div class="preview-thumbnail">
                      <img src="panel/assets/images/faces/face1.jpg" alt="image" class="profile-pic" />
                    </div>  */}
                  {/*}    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Manage Profile</b></h6>
                      <p class="text-gray mb-0 text-primary" onClick={()=>{ window.location.replace("/networking-startup-edit"); }}>Edit Startup Profile <i class="mdi mdi-chevron-double-right"></i></p>
                      <p class="text-gray mb-0 text-primary" onClick={()=>{ sessionStorage.setItem("NetworkVal00","startup"); getNavbarData();  window.location.replace("/networking-startup-dashboard"); }}>Switch to Mentor <i class="mdi mdi-chevron-double-right"></i></p>
                      </div> */}

                      <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                   {/*}   <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Mentor Profile </b></h6>
                      <p class="text-gray mb-0 text-primary" onClick={()=>{ sessionStorage.setItem("MentorId10",nav_mentor_id); sessionStorage.setItem("MentorKey10",nav_mentor_key); window.location.replace("/networking-mentor-edit"); }}>Edit Mentor Profile <i class="mdi mdi-chevron-double-right"></i></p>
                      <p class="text-gray mb-0 text-primary" onClick={()=>{ sessionStorage.setItem("NetworkVal00","mentor"); getNavbarData();  window.location.replace("/networking-startup-dashboard"); }}>Switch to Mentor Profile <i class="mdi mdi-chevron-double-right"></i></p>
                     */} 
                      <h6 class="preview-subject ellipsis mb-1 font-weight-normal"><b>Startups</b></h6>
                      {
                       nav_startup_list.map((curElm101,ind)=>{

                         return(
                         <>
                      <p class="text-gray mb-0 text-primary" onClick={()=>{ 
                         sessionStorage.setItem("StartupId10",curElm101.Startup_id); sessionStorage.setItem("StartupKey10",curElm101.Startup_key);
                        window.location.replace("/networking-startup-edit");  
                        }}>Edit {curElm101.Startup_name} <i class="mdi mdi-chevron-double-right"></i></p>
                           </>
                         )
                         }
					   
	                  		 )
                         }

                   {/*}   <p class="text-gray mb-0 text-primary" onClick={()=>{ sessionStorage.setItem("NetworkVal00","startup"); getNavbarData();  window.location.replace("/networking-mentor-dashboard"); }}>Switch to Startup Profile <i class="mdi mdi-chevron-double-right"></i></p>
                    */}  </div>

                  </a>
                  <div class="dropdown-divider"></div>
                
                
                  <div class="dropdown-divider"></div>
                  <h6 class="p-3 mb-0 text-center text-primary font-13" onClick={()=>{

                     //Remove Session Variables and redirect to login
                     sessionStorage.removeItem("logStatus00");
                     sessionStorage.removeItem("logId00");
                     sessionStorage.removeItem("logToken00");
                     sessionStorage.removeItem("BusyId00");
                     sessionStorage.removeItem("CounterId00");
                     sessionStorage.removeItem("logName00");
                     sessionStorage.removeItem("NetworkVale00");
                     window.location.replace("/login");


                  }}>LogOut</h6>
                </div>
              </li>
              <li class="nav-item dropdown ms-3">
                <a class="nav-link" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                 {/*} <i class="mdi mdi-bell-outline"></i> */}<i class="mdi mdi-help-circle-outline" style={{fontSize:"2rem"}}></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                  <h6 class="px-3 py-3 font-weight-semibold mb-0">Help Box</h6>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" href="/businesstools-feedback">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-info">
                       {/*} <i class="mdi mdi-calendar"></i> */}<i class="mdi mdi-comment-text"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">Feedback</h6>
                      <p class="text-gray ellipsis mb-0"> available 24*7</p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" href="/businesstools-support">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-warning">
                        {/*<i class="mdi mdi-settings"></i>*/}<i class="mdi mdi-headset"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">Support</h6>
                      <p class="text-gray ellipsis mb-0">from 10:00am to 8:00pm </p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item preview-item" aria-label="Chat on WhatsApp" href="https://wa.me/918480002413?text=I%20want%20support%20on%20Startup%20Connect's%20Networking%20Platform">
                    <div class="preview-thumbnail">
                      <div class="preview-icon bg-success">
                     <i class="mdi mdi-whatsapp"></i>
                      </div>
                    </div>
                    <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 class="preview-subject font-weight-normal mb-0">WhatsApp</h6>
                      <p class="text-gray ellipsis mb-0">from 10:00am to 8:00pm </p>
                    </div>
                  </a>
                  <div class="dropdown-divider"></div>
               {/*}   <h6 class="p-3 font-13 mb-0 text-primary text-center">View all notifications</h6> */}
                </div>
              </li>
            </ul>
            <ul class="navbar-nav navbar-nav-right">
             {/*} <li class="nav-item nav-logout d-none d-md-block me-3">
                <a class="nav-link" href="#">Status</a>
              </li> */}
            {/*}  <li class="nav-item nav-logout d-none d-md-block">
                <button class="btn btn-sm btn-danger">Trailing</button>
              </li> */}
          {/*}   <li class="nav-item nav-profile dropdown d-none d-md-block">
                <a class="nav-link dropdown-toggle" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="mdi mdi-account-circle" style={{fontSize:"3rem"}}></i>
                </a>
                <div class="dropdown-menu center navbar-dropdown" aria-labelledby="profileDropdown" style={{marginRight:"2rem"}}>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-bl me-3"></i> My Profile </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-cn me-3"></i> Team </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-de me-3"></i> <b>Plan Details : </b> Basic </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="flag-icon flag-icon-ru me-3"></i><b>Valid Till : </b> 12-02-2024</a>
                </div>
              </li> 
              <li></li>
              <li></li> */}
             {/*} <li class="nav-item nav-logout d-none d-lg-block">
                <a class="nav-link" href="index.html">
                  <i class="mdi mdi-home-circle"></i>
                </a>
              </li> */}
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span class="mdi mdi-menu"></span>
            </button> 
          </div>
        </nav>
    </>
);

}
export default Navbar;