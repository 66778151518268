import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';



import '../Resources/dashboard.css';


function Insideadmin_addsalesteam(){


  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");



 
//saving default values with usestate
const state = useState();

//steam_id
const[steam_id,setsteam_id]=useState();const updatesteam_id = (event) => { setsteam_id(event.target.value);}; const[steam_id_er,setsteam_id_er]=useState();
//team_id
const[team_id,setteam_id]=useState();const updateteam_id = (event) => { setteam_id(event.target.value);}; const[team_id_er,setteam_id_er]=useState();
//username
const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//password
const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);}; const[password_er,setpassword_er]=useState();
//cpassword
const[cpassword,setcpassword]=useState();const updatecpassword = (event) => { setcpassword(event.target.value);}; const[cpassword_er,setcpassword_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);}; const[email_er,setemail_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//city
const[city,setcity]=useState();const updatecity = (event) => { setcity(event.target.value);}; const[city_er,setcity_er]=useState();
//cstate
const[cstate,setcstate]=useState();const updatecstate = (event) => { setcstate(event.target.value);}; const[cstate_er,setcstate_er]=useState();
//country
const[country,setcountry]=useState();const updatecountry = (event) => { setcountry(event.target.value);}; const[country_er,setcountry_er]=useState();
//pincode
const[pincode,setpincode]=useState();const updatepincode = (event) => { setpincode(event.target.value);}; const[pincode_er,setpincode_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);}; const[type_er,settype_er]=useState();
//date_of_joining
const[date_of_joining,setdate_of_joining]=useState();const updatedate_of_joining = (event) => { setdate_of_joining(event.target.value);}; const[date_of_joining_er,setdate_of_joining_er]=useState();
//date_of_birth
const[date_of_birth,setdate_of_birth]=useState();const updatedate_of_birth = (event) => { setdate_of_birth(event.target.value);}; const[date_of_birth_er,setdate_of_birth_er]=useState();



//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();




  
//******************* ADD SALES TEAM MEMBER FUNCTION START ********************//
function addsalesteam(){

  //Setting Error Variables
  setsteam_id_er(" "); setteam_id_er(" "); setusername_er(" "); setname_er(" "); setpassword_er(" "); setcpassword_er(" "); setemail_er(" "); setcontact_er(" "); setcity_er(); setcstate_er(); setcountry_er(); setpincode_er(); settype_er(); setdate_of_birth_er(); setdate_of_joining_er(); seterror_msg(" ");

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 
  axios.post("bt-add-inside-salesteam-member", {
   Log_id:logid,
   Token:token,
   Steam_id:steam_id,
   Team_id:team_id,
   Username:username,
   Password:password,
   Confirm_password:cpassword,
   Name : name,
   Email:email,
   Contact : contact,
   City:city,
   State:cstate,
   Country:country,
   Pincode:pincode,
   Type:type,
   Date_of_joining:date_of_joining,
   Date_of_birth:date_of_birth


  })
  .then((response) => {

   

  //Customers Limit Reached
  if(response.data.error==111){     seterror_msg(response.data.error_msg); return; }

      
 else if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("/");
  }

   //Validation Errors
  else if(response.data.validatorerror){
  setsteam_id_er(response.data.validatorerror.Steam_id);
  setteam_id_er(response.data.validatorerror.Team_id);
  setusername_er(response.data.validatorerror.Username);
  setname_er(response.data.validatorerror.Name);
  setemail_er(response.data.validatorerror.Email);
  setcontact_er(response.data.validatorerror.Contact);
  setpassword_er(response.data.validatorerror.Password);
  setcpassword_er(response.data.validatorerror.Confirm_password);
  setcity_er(response.data.validatorerror.City);
  setcstate_er(response.data.validatorerror.State);  
  setcountry_er(response.data.validatorerror.Country);
  setpincode_er(response.data.validatorerror.Pincode);  
  settype_er(response.data.validatorerror.Type);
  setdate_of_birth_er(response.data.validatorerror.Date_of_birth);
  setdate_of_joining_er(response.data.validatorerror.Date_of_joining);
 }
//Invalid Entries
else if(response.data.error==102){ 
  
  setsteam_id_er(response.data.steam_id_er);
  setteam_id_er(response.data.team_id_er);
  setusername_er(response.data.username_er);
  setname_er(response.data.name_er);
  setemail_er(response.data.email_er);
  setcontact_er(response.data.contact_er);
  setpassword_er(response.data.password_er);
  setcpassword_er(response.data.confirm_password_er);
  setcity_er(response.data.city_er);
  setcstate_er(response.data.state_er);
  setcountry_er(response.data.country_er);
  setpincode_er(response.data.pincode_er);
  settype_er(response.data.type_er);
  setdate_of_joining_er(response.data.date_of_joining_er);
  setdate_of_birth_er(response.data.date_of_birth_er);
  seterror_msg("Cannot Add Sales Team Member!");  }

 //Email Already Exists
 else if(response.data.error==103){  seterror_msg(response.data.error_msg); } 

   //Transaction Entered Successfully
  else if(response.data.successvar==1){  document.getElementById("success-card").style.display="block";  }
   //Cannot Add Transaction
   else{  seterror_msg("Cannot Add Sales Team Member !"); }


  


});

}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* ADD SALES TEAM MEMBER FUNCTION END ********************//





return(
    <>
                <div class="card" style={{textAlign:"left"}}>
                <div class="d-flex justify-content-between">
                    <div></div>
                    <div>
                    
                      <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("create-form").style.display="none"; }}>
                      <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                      </button>
                      </div>
                    </div>
                  <div class="card-body">
                    <h4 class="card-title">Add New Sales Team Member</h4>
                    <div class="form-sample">
                      <p class="card-description">add new sales team member</p>
                      <code>{error_msg}</code>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="STEAM123" id="steam_id" value={steam_id} onChange={updatesteam_id} />
                              <code>{steam_id_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="TEAM1234" id="team_id" value={team_id} onChange={updateteam_id} />
                              <code>{team_id_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="team_id" class="form-control" placeholder="MYUSER123" id="username" value={username} onChange={updateusername} />
                              <code>{username_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name<code>*</code></label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Suman Kumar" id="name" value={name} onChange={updatename} />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="sumankumar123@gmail.com" id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="91 84754 00000" id="contact" value={contact} onChange={updatecontact} />
                              <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date_of_joining</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_joining" value={date_of_joining} onChange={updatedate_of_joining} />
                              <code>{date_of_joining_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date_of_birth</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control"  id="date_of_birth" value={date_of_birth} onChange={updatedate_of_birth} />
                              <code>{date_of_birth_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Lucknow" id="city" value={city} onChange={updatecity} />
                              <code>{city_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Uttar Pradesh" id="cstate" value={cstate} onChange={updatecstate} />
                              <code>{cstate_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Country</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="INDIA"  id="country" value={country} onChange={updatecountry}/>
                              <code>{country_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Pincode</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="420110" id="pincode" value={pincode} onChange={updatepincode} />
                              <code>{pincode_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="***********"  id="password" value={password} onChange={updatepassword}/>
                              <code>{password_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Confirm Password</label>
                            <div class="col-sm-9">
                              <input type="password" class="form-control" placeholder="***********" id="cpassword" value={cpassword} onChange={updatecpassword} />
                              <code>{cpassword_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Regular" id="type" value={type} onChange={updatetype} />
                              <code>{type_er}</code>
                            </div>
                          </div>
                        </div>
                   
                    
                      

                     
                       
                      </div>
  
                        <button class="btn btn-primary" style={{fontWeight:"bold"}} onClick={addsalesteam}>Add Sales Team</button>
                      
                   
                    </div>
                  </div>
                </div>
    </>

);

}

export default Insideadmin_addsalesteam;