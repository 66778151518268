import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';

const logname=sessionStorage.getItem("logName00");
const logid=sessionStorage.getItem("logId00");

function Sidebar(){

//filtername
const[filtername,setfiltername]=useState();const updatefiltername = (event) => { setfiltername(event.target.value);}; const[filtername_er,setfiltername_er]=useState();
//filtercurrent_company
const[filtercurrent_company,setfiltercurrent_company]=useState();const updatefiltercurrent_company = (event) => { setfiltercurrent_company(event.target.value);}; const[filtercurrent_company_er,setfiltercurrent_company_er]=useState();
//filtercurrent_designation
const[filtercurrent_designation,setfiltercurrent_designation]=useState();const updatefiltercurrent_designation = (event) => { setfiltercurrent_designation(event.target.value);}; const[filtercurrent_designation_er,setfiltercurrent_designation_er]=useState();
//filterindustry
const[filterindustry,setfilterindustry]=useState();const updatefilterindustry = (event) => { setfilterindustry(event.target.value);}; const[filterindustry_er,setfilterindustry_er]=useState();
//filtersector
const[filtersector,setfiltersector]=useState();const updatefiltersector = (event) => { setfiltersector(event.target.value);}; const[filtersector_er,setfiltersector_er]=useState();
//filtercategory
const[filtercategory,setfiltercategory]=useState();const updatefiltercategory = (event) => { setfiltercategory(event.target.value);}; const[filtercategory_er,setfiltercategory_er]=useState();
//filtersub_category
const[filtersub_category,setfiltersub_category]=useState();const updatefiltersub_category = (event) => { setfiltersub_category(event.target.value);}; const[filtersub_category_er,setfiltersub_category_er]=useState();
//filterexperience
const[filterexperience,setfilterexperience]=useState();const updatefilterexperience = (event) => { setfilterexperience(event.target.value);}; const[filterexperience_er,setfilterexperience_er]=useState();
//filtercity
const[filtercity,setfiltercity]=useState();const updatefiltercity = (event) => { setfiltercity(event.target.value);}; const[filtercity_er,setfiltercity_er]=useState();
//filterstate
const[filterstate,setfilterstate]=useState();const updatefilterstate = (event) => { setfilterstate(event.target.value);}; const[filterstate_er,setfilterstate_er]=useState();
//filtercountry
const[filtercountry,setfiltercountry]=useState();const updatefiltercountry = (event) => { setfiltercountry(event.target.value);}; const[filtercountry_er,setfiltercountry_er]=useState();



return(
    <>
      {/*} partial:partials/_sidebar.html */}
      {/* MENTOR DASHBOARD SIDEBAR START */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="/networking-login" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" />
                {/*}change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
                <span class="text-secondary icon-sm text-center mt-2">Session : SE{logid}098</span>
              </div>
            </a>
          </li>
          <li class="pt-2 pb-1">
          <h3>Filters</h3>
          </li> 
       



          <li class="nav-item mt-2" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Mentor Name</label>
              <input type="text" class="form-control" placeholder="Stuart Soft" id="filtername" value={filtername} onChange={updatefiltername} />
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Current Designation</label>
              <input type="text" class="form-control" placeholder="Chief Executive Officer" id="filtercurrent_designation" value={filtercurrent_designation} onChange={updatefiltercurrent_designation}  />
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Current Company</label>
              <input type="text" class="form-control" placeholder="ABC Private Limited" id="filtercurrent_company" value={filtercurrent_company} onChange={updatefiltercurrent_company}  />
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Industry</label>
              <select class="form-control" style={{padding:"1rem"}} id="filterindustry" onChange={updatefilterindustry} >
                <option>Select Industry</option>
                <option value="Technology">Technology</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Finance">Finance</option>
                <option value="Education">Education</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Retail">Retail</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Energy">Energy</option>
                <option value="Transaportaion">Transaportation</option>
                <option value="Construction">Construction</option>
                <option value="Business Assistance">Business Assistance</option>
                <option value="Other">Other</option>
              </select>        
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Category 1</label>
              <select style={{padding:"1rem"}} class="form-control" id="filtercategory" onChange={updatefiltercategory} >
                <option>Select Category 1</option>
                <option value="AI and ML">AI and ML</option>
                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                <option value="Internet of Things">Internet of Things</option>
                <option value="Big Data and Analytics">Big Data and Analytics</option>
                <option value="AR and VR">AR and VR</option>
                <option value="Robotics and Automation">Robotics and Automation</option>
                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                <option value="Education and Skill Development">Education and Skill Development</option>
                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                <option value="Social Justice and Equality">Social Justice and Equality</option>
                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                <option value="Personalized Experiences">Personalized Experiences</option>
                <option value="Consultancy and Services">Consultancy and Services</option>
                <option value="Travel and Tourism">Travel and Tourism</option>
                <option value="Animations and Gaming">Animations and Gaming</option>
                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                <option value="Social Groups">Social Groups</option>
                <option value="Others">Others</option>
              </select>        
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Category 2</label>
              <select style={{padding:"1rem"}} class="form-control" id="filtersub_category" onChange={updatefiltersub_category} >
                <option>Select Category 2</option>
                <option value="AI and ML">AI and ML</option>
                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                <option value="Internet of Things">Internet of Things</option>
                <option value="Big Data and Analytics">Big Data and Analytics</option>
                <option value="AR and VR">AR and VR</option>
                <option value="Robotics and Automation">Robotics and Automation</option>
                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                <option value="Education and Skill Development">Education and Skill Development</option>
                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                <option value="Social Justice and Equality">Social Justice and Equality</option>
                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                <option value="Personalized Experiences">Personalized Experiences</option>
                <option value="Consultancy and Services">Consultancy and Services</option>
                <option value="Travel and Tourism">Travel and Tourism</option>
                <option value="Animations and Gaming">Animations and Gaming</option>
                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                <option value="Social Groups">Social Groups</option>
                <option value="Others">Others</option>
              </select>        
            </div>
          </li>

          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Sector</label>
              <select class="form-control" style={{padding:"1rem"}} id="filtersector" onChange={updatefiltersector} >
                <option>Select Sector</option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
                <option value="B2B2C">B2B2C</option>
              </select>     
            </div>
          </li>

          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">City</label>
              <input type="text" class="form-control" placeholder="City" id="filtercity" value={filtercity} onChange={updatefiltercity} />
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">State</label>
              <input type="text" class="form-control" placeholder="State" id="filterstate" value={filterstate} onChange={updatefilterstate} />
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Country</label>
              <input type="text" class="form-control" placeholder="Country" id="filtercountry" value={filtercountry} onChange={updatefiltercountry} />
            </div>
          </li>
         
          <li class="nav-item mt-3">
            <button class="btn btn-lg btn-success">Search</button>
          </li>

        </ul>
      </nav>
       {/* MENTOR DASHBOARD SIDEBAR END */}
    </>

);

}

export default Sidebar;