import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

//import './Pricingdummycss.css';

function Pricingdummy(){


      return(
      <>


<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Get your startup services relating to website development, consultancy, taxation, business registration, software services, intern hiring, social media management, digital marketing and much more.  "></meta>
    
   {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />*/}
        <link rel="icon" href="./images/startupconnect_icon.ico" />
    <link rel="apple-touch-icon" href="./images/startupconnect_icon.png" />

      {/* plugins:css */}
      <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="./images/startupconnect_icon.png" />


    {/*} Bootstrap CSS */}
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
 

    <title>theStartupConnect | Business Tools and Services</title>
  </head>
  <body>
{/*}
<h1>New Body Heading</h1>
<h2>No one here</h2>
<h3>Go oemme</h3>
<h4>OKNSSNMNJ djc hehrhrh </h4>
<h5>jguy uugyr yfr645</h5>
<h6>jhuyt 6565dx uty565d45d343</h6>
<p>jhdwuicyu GGY yfydddfl</p>
*/}

      <div>
      <div class="card-body">
                    <h4 class="card-title">Pricing Plans</h4>
                      <p class="card-description">new pricing plans</p>
                      <code></code>
                      <div class="row">
                        <div class="col-md-3 card">
                          <h3>Free Tier Plan</h3>
                          <ul class="list-ticked">
                            <li>First Feature in price</li>
                            <li>Second Feature in price</li>
                            <li>Third Feature in price</li>
                            <li>Fourth Feature in price</li>
                            <li>Fifth Feature in price</li>

                          </ul>
                        <button class="btn btn-success" onClick={()=>{
                        
                        // Assign value to pricingplan
                        sessionStorage.setItem("pricingplan00", "Test123");
                        window.location.replace("/bt-pricing-page");
                       }}>Start Free Trial</button>
                        </div>
                        <div class="col-md-3 card">
                          <h3>Basic Plan</h3>
                          <ul class="list-ticked">
                            <li>First Feature in price</li>
                            <li>Second Feature in price</li>
                            <li>Third Feature in price</li>
                            <li>Fourth Feature in price</li>
                            <li>Fifth Feature in price</li>

                          </ul>
                        <button class="btn btn-success">Start Basic Plan</button>
                        </div>
                        <div class="col-md-3 card">
                          <h3>Standard Plan</h3>
                          <ul class="list-ticked">
                            <li>First Feature in price</li>
                            <li>Second Feature in price</li>
                            <li>Third Feature in price</li>
                            <li>Fourth Feature in price</li>
                            <li>Fifth Feature in price</li>

                          </ul>
                        <button class="btn btn-success">Start Standard Plan</button>
                        </div>

                        </div>
                        </div>
      </div>

      {/*} Optional JavaScript; choose one of the two! */}

    {/*} Option 1: Bootstrap Bundle with Popper */}
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

    {/*} Option 2: Separate Popper and Bootstrap JS */}
    {/*}
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
    */}

    

    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}

  </body>
</html>
</>
      );

}

export default Pricingdummy;