import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';

 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const logname=sessionStorage.getItem("logName00");


 

function Insideadmin_sidebar(){

return(
    <>
      {/*} partial:partials/_sidebar.html */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="/businesstools-login" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" />
                {/*}change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
                <span class="text-secondary icon-sm text-center mt-2">Session : SE{logid}098</span>
              </div>
            </a>
          </li>
          <li class="nav-item pt-3">
            <a class="nav-link d-block" href="/home">
              <img class="sidebar-brand-logo" src="/home1/weblogo.png" alt="" height="100rem" width="100rem" />
              <img class="sidebar-brand-logomini" src="/home1/weblogo.png" alt="" height="50rem" width="50rem" />
            {/*}  <div class="small font-weight-light pt-1">Responsive Dashboard</div> */}
            </a>
          </li>
        {/*}  <li class="pt-2 pb-1">
            <span class="nav-item-head">Dashboard</span>
          </li> */}
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-registrations">
              <i class="mdi mdi-contacts menu-icon"></i>
              <span class="menu-title">Registrations</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-loginhistory">
              <i class="mdi mdi-account-multiple-outline menu-icon"></i>
              <span class="menu-title">Logins</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-salesteam">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Sales Team</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-salescoupons">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Sales Coupons</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-salescoupons-history">
              <i class="mdi mdi-cash-multiple menu-icon"></i>
              <span class="menu-title">Sales Coupons History</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-orders">
              <i class="mdi mdi-bank menu-icon"></i>
              <span class="menu-title">Orders</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/businesstools-insideadmin-payments">
              <i class="mdi mdi-drawing-box menu-icon"></i>
              <span class="menu-title">Payments</span>
            </a>
          </li>

        </ul>
      </nav>
    </>

);

}

export default Insideadmin_sidebar;