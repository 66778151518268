import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
//import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Navbar from '../navbar.js';
import Sidebar from './sidebar';
import axios from '../../axios.js';



import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Mentordashboard(){

   // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");

 const[data,setData] = useState([]);//SetData Variable

 //const imgpath = 'http://127.0.0.1:8000/storage/';
const imgpath = 'https://thestartupconnect.com/apidata/storage/';

//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

//Error Variable
const[errorvar,seterrorvar] = useState();



//FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);
//filtername
const[filtername,setfiltername]=useState();const updatefiltername = (event) => { setfiltername(event.target.value);}; const[filtername_er,setfiltername_er]=useState();
//filtercurrent_company
const[filtercurrent_company,setfiltercurrent_company]=useState();const updatefiltercurrent_company = (event) => { setfiltercurrent_company(event.target.value);}; const[filtercurrent_company_er,setfiltercurrent_company_er]=useState();
//filtercurrent_designation
const[filtercurrent_designation,setfiltercurrent_designation]=useState();const updatefiltercurrent_designation = (event) => { setfiltercurrent_designation(event.target.value);}; const[filtercurrent_designation_er,setfiltercurrent_designation_er]=useState();
//filterindustry
const[filterindustry,setfilterindustry]=useState();const updatefilterindustry = (event) => { setfilterindustry(event.target.value);}; const[filterindustry_er,setfilterindustry_er]=useState();
//filtersector
const[filtersector,setfiltersector]=useState();const updatefiltersector = (event) => { setfiltersector(event.target.value);}; const[filtersector_er,setfiltersector_er]=useState();
//filtercategory
const[filtercategory,setfiltercategory]=useState();const updatefiltercategory = (event) => { setfiltercategory(event.target.value);}; const[filtercategory_er,setfiltercategory_er]=useState();
//filtersub_category
const[filtersub_category,setfiltersub_category]=useState();const updatefiltersub_category = (event) => { setfiltersub_category(event.target.value);}; const[filtersub_category_er,setfiltersub_category_er]=useState();
//filterexperience
const[filterexperience,setfilterexperience]=useState();const updatefilterexperience = (event) => { setfilterexperience(event.target.value);}; const[filterexperience_er,setfilterexperience_er]=useState();
//filtercity
const[filtercity,setfiltercity]=useState();const updatefiltercity = (event) => { setfiltercity(event.target.value);}; const[filtercity_er,setfiltercity_er]=useState();
//filterstate
const[filterstate,setfilterstate]=useState();const updatefilterstate = (event) => { setfilterstate(event.target.value);}; const[filterstate_er,setfilterstate_er]=useState();
//filtercountry
const[filtercountry,setfiltercountry]=useState();const updatefiltercountry = (event) => { setfiltercountry(event.target.value);}; const[filtercountry_er,setfiltercountry_er]=useState();




useEffect(()=>{
   
  getData();
  
  },[])
  
//******************* GET MENTORS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 axios.post("network-get-mentors", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

    //Filters
    'Filter_val' :filterval,
    'Filtername' :filtername,
    'Filtercurrent_designation' : filtercurrent_designation,
    'Filtercurrent_company' : filtercurrent_company,
    'Filterindustry' : filterindustry,
    'Filtercategory' : filtercategory,
    'Filtesub_rcategory' : filtersub_category,
    'Filtersector' : filtersector,
    'Filtercity' : filtercity,
    'Filterstate' : filterstate,
    'Filtercountry' : filtercountry


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");

     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }


  //Validation Errors
  if(response.data.validatorerror){

   //Setting Filter Validation Errors
   setfiltername_er(response.data.validatorerror.Filtername);
   setfiltercurrent_designation_er(response.data.validatorerror.Filtercurrent_designation);
   setfiltercurrent_company_er(response.data.validatorerror.Filtercurrent_company);
   setfilterindustry_er(response.data.validatorerror.Filterindustry);
   setfiltersector_er(response.data.validatorerror.Filtersector);
   setfiltercategory_er(response.data.validatorerror.Filtercategory);
   setfiltersub_category_er(response.data.validatorerror.Filtersub_category);
   setfiltercity_er(response.data.validatorerror.Filtercity);
   setfilterstate_er(response.data.validatorerror.Filterstate);
   setfiltercountry_er(response.data.validatorerror.Filtercountry);
   seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
   //Setting Filter Violation Errors
   setfiltername_er(response.data.filtername_er);
   setfiltercurrent_designation_er(response.data.filtercurrent_designation_er);
   setfiltercurrent_company_er(response.data.filtercurrent_company_er);
   setfilterindustry_er(response.data.filterindustry_er);
   setfiltersector_er(response.data.filtersector_er);
   setfiltercategory_er(response.data.filtercategory_er);
   setfiltersub_category_er(response.data.filtersub_category_er);
   setfiltercity_er(response.data.filtercity_er);
   setfilterstate_er(response.data.filterstate_er);
   setfiltercountry_er(response.data.filtercountry_er);
   seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");


   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET MENTORS DATA FUNCTION END  ********************//


return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Neworking | Mentors</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">

      {/* MENTOR DASHBOARD SIDEBAR START */}
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile border-bottom">
            <a href="/networking-login" class="nav-link flex-column">
              <div class="nav-profile-image">
                <img src="https://cdn-icons-png.flaticon.com/128/9069/9069049.png" alt="profile" />
                {/*}change to offline or busy as needed*/}
              </div>
              <div class="nav-profile-text d-flex ms-0 mb-3 flex-column">
                <span class="font-weight-semibold mb-1 mt-2 text-center">{logname}</span>
                <span class="text-secondary icon-sm text-center mt-2">Session : SE{logid}098</span>
              </div>
            </a>
          </li>
          <li class="pt-2 pb-1">
          <h3>Filters</h3>
          </li> 
       



          <li class="nav-item mt-2" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Mentor Name</label>
              <input type="text" class="form-control" placeholder="Stuart Soft" id="filtername" value={filtername} onChange={updatefiltername} />
              <code>{filtername_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Current Designation</label>
              <input type="text" class="form-control" placeholder="Chief Executive Officer" id="filtercurrent_designation" value={filtercurrent_designation} onChange={updatefiltercurrent_designation}  />
            <code>{filtercurrent_designation_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Current Company</label>
              <input type="text" class="form-control" placeholder="ABC Private Limited" id="filtercurrent_company" value={filtercurrent_company} onChange={updatefiltercurrent_company}  />
            <code>{filtercurrent_company_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Industry</label>
              <select class="form-control" style={{padding:"1rem"}} id="filterindustry" onChange={updatefilterindustry} >
                <option>Select Industry</option>
                <option value="Technology">Technology</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Finance">Finance</option>
                <option value="Education">Education</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Retail">Retail</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Energy">Energy</option>
                <option value="Transaportaion">Transaportation</option>
                <option value="Construction">Construction</option>
                <option value="Business Assistance">Business Assistance</option>
                <option value="Other">Other</option>
              </select>  
              <code>{filterindustry_er}</code>      
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Category 1</label>
              <select style={{padding:"1rem"}} class="form-control" id="filtercategory" onChange={updatefiltercategory} >
                <option>Select Category 1</option>
                <option value="AI and ML">AI and ML</option>
                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                <option value="Internet of Things">Internet of Things</option>
                <option value="Big Data and Analytics">Big Data and Analytics</option>
                <option value="AR and VR">AR and VR</option>
                <option value="Robotics and Automation">Robotics and Automation</option>
                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                <option value="Education and Skill Development">Education and Skill Development</option>
                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                <option value="Social Justice and Equality">Social Justice and Equality</option>
                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                <option value="Personalized Experiences">Personalized Experiences</option>
                <option value="Consultancy and Services">Consultancy and Services</option>
                <option value="Travel and Tourism">Travel and Tourism</option>
                <option value="Animations and Gaming">Animations and Gaming</option>
                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                <option value="Social Groups">Social Groups</option>
                <option value="Others">Others</option>
              </select>        
              <code>{filtercategory_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Category 2</label>
              <select style={{padding:"1rem"}} class="form-control" id="filtersub_category" onChange={updatefiltersub_category} >
                <option>Select Category 2</option>
                <option value="AI and ML">AI and ML</option>
                <option value="Fintech and BlockChain">Fintech and Blockchain</option>
                <option value="Cyber Security and Data Privacy">Cyber Security and Data Privacy</option>
                <option value="Internet of Things">Internet of Things</option>
                <option value="Big Data and Analytics">Big Data and Analytics</option>
                <option value="AR and VR">AR and VR</option>
                <option value="Robotics and Automation">Robotics and Automation</option>
                <option value="Space Exploration and Technology">Space Exploration and Technology</option>
                <option value="Education and Skill Development">Education and Skill Development</option>
                <option value="Healthcare and Medical Technology">Healthcare and Medical Technology</option>
                <option value="Renewbale Energy and Clean Tech">Renewbale Energy and Clean Tech</option>
                <option value="Circular Economy and Waste Management">Circular Economy and Waste Management</option>
                <option value="Sustainable Agriculture and Food Tech">Sustainable Agriculture and Food Tech</option>
                <option value="Urban Development and Smart Cities">Urban Development and Smart Cities</option>
                <option value="Social Justice and Equality">Social Justice and Equality</option>
                <option value="Disaster Relief and Preparedness">Disaster Relief and Preparedness</option>
                <option value="Ecommerce and Retail Tech">Ecommerce and Retail Tech</option>
                <option value="On Demand Services and Gig Economy">On Demand Services and Gig Economy</option>
                <option value="Personalized Experiences">Personalized Experiences</option>
                <option value="Consultancy and Services">Consultancy and Services</option>
                <option value="Travel and Tourism">Travel and Tourism</option>
                <option value="Animations and Gaming">Animations and Gaming</option>
                <option value="Marketing Promotion and Public Relations">Marketing Promotion and Public Relations</option>
                <option value="Social Groups">Social Groups</option>
                <option value="Others">Others</option>
              </select>        
              <code>{filtersub_category_er}</code>
            </div>
          </li>

          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Sector</label>
              <select class="form-control" style={{padding:"1rem"}} id="filtersector" onChange={updatefiltersector} >
                <option>Select Sector</option>
                <option value="B2B">B2B</option>
                <option value="B2C">B2C</option>
                <option value="B2B2C">B2B2C</option>
              </select>     
              <code>{filtersector_er}</code>
            </div>
          </li>

          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">City</label>
              <input type="text" class="form-control" placeholder="City" id="filtercity" value={filtercity} onChange={updatefiltercity} />
              <code>{filtercity_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">State</label>
              <input type="text" class="form-control" placeholder="State" id="filterstate" value={filterstate} onChange={updatefilterstate} />
              <code>{filterstate_er}</code>
            </div>
          </li>
          <li class="nav-item mt-3" style={{textAlign:"left"}}>
            <div>
              <label class="menu-title font-weight-semibold text-align-center">Country</label>
              <input type="text" class="form-control" placeholder="Country" id="filtercountry" value={filtercountry} onChange={updatefiltercountry} />
              <code>{filtercountry_er}</code>
            </div>
          </li>
         
          <li class="nav-item mt-3">
            <button class="btn btn-lg btn-success" onClick={getData}>Search</button>
          </li>

        </ul>
      </nav>
       {/* MENTOR DASHBOARD SIDEBAR END */}
    <div class="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div class="main-panel">
       
  

          <div class="content-wrapper pb-0">

         
       


            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">Mentor Dashboard</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                  {/*}  <p class="m-0">ADE-00234</p> */}
                  </a>
                </div>
               {/*} <button type="button" class="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i class="mdi mdi-plus-circle"></i> Add New Business </button> */}
              </div>
            </div>
        
          
            <div class="row">
            {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                          {/* MENTOR PROFILE CARD START 0 */}
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                  
                {/*}    <img class="img-fluid w-100" src="http://127.0.0.1:8000/storage/Mentors/US505zZhNuYXsDnwa82Iiyh7E2P6nutljpiPlvE3.png"
                     alt="" style={{height:"40vh"}} /> */}
                     <img class="img-fluid w-100" src={imgpath+curElm.Profile_link.slice(7)}
                       alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> {curElm.Current_designation} at <b>{curElm.Current_company}</b></p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> {curElm.Name} </h5>
                    <p>{curElm.Introduction}</p>
                 {/*}    <hr />
                   <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>{curElm.Rating/curElm.Session_counts}/5 ({curElm.Rating})
                      </p>
                        <p class="mb-0">₹ {curElm.Session_price} / session ({curElm.Session_duration} mins)</p>                        
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div> */}

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <a href={curElm.Youtube}><button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button></a>
                      <a href={curElm.Twitter}><button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button></a>
                      <a href={curElm.LinkedIn}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button></a>
                      <a href={curElm.Website}><button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem", backgroundColor:"#2cdba0"}}>
                        <i class="mdi mdi-web"></i>
                      </button></a>
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#{curElm.Industry} #{curElm.Sector} #{curElm.Category} #{curElm.Sub_category}</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 0 */}
              </>
                         )
                         }
					   
	                  		 )
                         }


            {/* MENTOR PROFILE CARD START 1 
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 1 */}

             {/* MENTOR PROFILE CARD START 2 
             <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 2 */}
              {/* MENTOR PROFILE CARD START 3 
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/2613260/pexels-photo-2613260.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 3 */}
               {/* MENTOR PROFILE CARD START 4
               <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/775358/pexels-photo-775358.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 4 */}
               {/* MENTOR PROFILE CARD START 5 
               <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/1898555/pexels-photo-1898555.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 5 */}
              {/* MENTOR PROFILE CARD START 6 
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/1933873/pexels-photo-1933873.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 6 */}
              {/* MENTOR PROFILE CARD START 7 
              <div class="col-sm-4 stretch-card grid-margin">
                <div class="card">
                  <div class="card-body p-0">
                    <img class="img-fluid w-100" src="https://images.pexels.com/photos/3193818/pexels-photo-3193818.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" style={{height:"40vh"}} />
                  </div>
                  <div class="card-body px-3 text-dark">
                    <div class="d-flex justify-content-between">
                      <p class="text-muted font-13 mb-0"> SENIOR MENTOR AND ASSOCIATE</p>
                      <i class="mdi mdi-heart-outline"></i>
                    </div>
                    <h5 class="font-weight-semibold" style={{marginTop:"1rem"}}> MRS. STUART MOMENT CHREW </h5>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold">
                      <p class="mb-0">
                        <i class="mdi mdi-star star-color pe-1"></i>4.60 (35) 
                      </p>
                        <p class="mb-0">₹ 4500 / session (40 mins)</p>
                    </div>
                    <div class="d-flex justify-content-between font-weight-semibold" style={{marginTop:"1rem"}}>
                    <button class="btn btn-outline-success"><i class="mdi mdi-calendar-clock"></i> Book a Meeting</button>                
                    <button class="btn btn-outline-warning"><i class="mdi mdi-comment-text"></i> Chat</button>
                    </div>

                    <div class="d-flex justify-content-center font-weight-semibold" style={{marginTop:"2rem"}}>
                    <div>
                      <button type="button" class="btn btn-social-icon btn-youtube btn-rounded" style={{fontSize:".5rem",padding:".5rem"}}>
                        <i class="mdi mdi-youtube"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-twitter btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-twitter"></i>
                      </button>
                      <button type="button" class="btn btn-social-icon btn-linkedin btn-rounded" style={{marginLeft:".5rem"}}>
                        <i class="mdi mdi-linkedin"></i>
                      </button>
                   
                      </div>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between font-weight-semibold" >
                    <p style={{textAlign:"left"}}>Talks About <br /> <span class="text-primary">#AI #ML #edutech #innovation</span></p>
                     
                      <button class="btn btn-icon"><i class="mdi mdi-information text-danger" ></i></button>

                    </div>
                  </div>
                </div>
              </div>
              {/* MENTOR PROFILE CARD END 7 */}
            </div>
       
            {/* PAGINATION SECTION START */}
            <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

         
             
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

   }

//}
export default Mentordashboard;