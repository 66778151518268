import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';

import '../Resources/dashboard.css';
import Navbar from '../navbar';


function Bt_support(){


   // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");


//saving default values with usestate
const state = useState();

//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//title
const[title,settitle]=useState();const updatetitle = (event) => { settitle(event.target.value);};  const[title_er,settitle_er]=useState();
//details
const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);};  const[details_er,setdetails_er]=useState();
//type
const[type,settype]=useState();const updatetype = (event) => { settype(event.target.value);};  const[type_er,settype_er]=useState();
//code
const[code,setcode]=useState();

//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();
//SUCCESS VARIABLES 
const[success_msg,setsuccess_msg]=useState();



/***************** CREATE SUPPORT TICKET FUNCTION START  ******************/
function create_support(){

    //Fetching User Consent Acceptance
    const iagree = document.getElementById('iagree');
    //User Agrees to Terms and Conditions and privacy policy continue to registration
  if (iagree.checked) {

//Setting Error Variables
setname_er(" "); setemail_er(" ");setcontact_er(" ");settitle_er(" ");setdetails_er(" ");settype_er(" ");
seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//Fetching Name Value
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $title = document.getElementById("title").value; if($title==""){ settitle_er("Question Title is required !");noerror = noerror+1; }//Fetching title Value
const $details = document.getElementById("details").value; if($details==""){ setdetails_er("Details is required !");noerror = noerror+1; }//Fetching details Value
const $type = document.getElementById("type").value; if($type==""){ settype_er("Type is required !");noerror = noerror+1; }//Fetching type Value


//No primary errors
if(noerror==0){
//Sending Data through axios
axios.post("bt-support", { 
Id:logid,
Account_type:logtype,
Name:$name,
Email:$email,
Contact:$contact,
Title:$title,
Type:$type,
Details:$details

})

.then((response) => {

//Feedbacks Limit Reached
if(response.data.error==105){     seterror_msg(response.data.error_msg); return; }


//Validation Errors
if(response.data.validatorerror){
setname_er(response.data.validatorerror.Name);
setcontact_er(response.data.validatorerror.Contact);
setemail_er(response.data.validatorerror.Email);
settitle_er(response.data.validatorerror.Title);
setdetails_er(response.data.validatorerror.Details);
settype_er(response.data.validatorerror.Type);

}
//Invalid Entries
if(response.data.error==102){ 

setname_er(response.data.name_er); 
setemail_er(response.data.email_er); 
setcontact_er(response.data.contact_er); 
settitle_er(response.data.title_er); 
setdetails_er(response.data.details_er); 
settype_er(response.data.type_er); 

}

//Success
if(response.data.successvar==1){


//window.location.replace("/businesstools-startworking");
setcode(response.data.code);
document.getElementById("success-card").style.display="block";

}
//failure
if(response.data.successvar==2){
seterror_msg(response.data.error_msg);
}
}    );

}

}
//User Not Select the CheckBox
else{
  //Show error
  seterror_msg("You need to agree to our Terms and Conditions and Privacy Policy to Continue!");
}

 

}
/***************** CREATE SUPPORT TICKET FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

        {/*}  <Navbar/> */}

          <div class="main-panel">
          <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Support Ticket Created Successfully!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> We have received your support query. and our team will be soon in touch with you to resolve your query.<br />
                      <span class="text-primary" >{code}</span> is your Support Ticket Key, keep this safe for further communication relating to your query.<br />
                      Click the following button to continue.</p>
                      <a href="/login"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

              </div>
          <div class="content-wrapper pb-0">
            <div class="row">
              <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
              <div class="card">
                  <div class="card-body">
                  <div >
                    <h2 class="card-title" style={{padding:"1rem",fontSize:"1.2rem"}}>Support Form</h2>
                    <p style={{marginLeft:"1rem"}}>raise your support ticket.</p>
                    <code>{error_msg}</code>
                    </div>
                    <p  style={{textAlign:"center"}}><code></code></p>
                    <div class="forms-sample">
                    <div class="form-group">
                        <label for="exampleInputUsername1">Name</label>
                        <input type="text" class="form-control" id="name" placeholder="Name" value={name} onChange={updatename}  />
                        <code>{name_er}</code>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputUsername1">Email</label>
                        <input type="text" class="form-control" id="email" placeholder="Email" value={email} onChange={updateemail}  />
                        <code>{email_er}</code>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputUsername1">Contact</label>
                        <input type="text" class="form-control" id="contact" placeholder="contact" value={contact} onChange={updatecontact}  />
                        <code>{contact_er}</code>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputUsername1">Type</label>
                        <select type="text" class="form-control" id="type" placeholder="type" value={type} onChange={updatetype} style={{padding:"1rem"}}>
                        <option value="">Select Ticket Type</option>
                        <option value="Feedback and Suggestions">Feedback and Suggestions</option>
                        <option value="General Information Requests">General Information Requests</option>
                        <option value="Account Management">Account Management</option>
                        <option value="Technical Support">Technical Support</option>
                        <option value="Sales Inquiries">Sales Inquiries</option>
                        <option value="Complaints and Escalations">Complaints and Escalations</option>
                        </select>
                        <code>{type_er}</code>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputUsername1">Question Title</label>
                        <input type="text" class="form-control" id="title" placeholder="title" value={title} onChange={updatetitle}  />
                        <code>{title_er}</code>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputUsername1">Details</label>
                        <textarea type="text" class="form-control" id="details" placeholder="details" value={details} onChange={updatedetails}></textarea>
                        <code>{details_er}</code>
                    </div>
                    <div class="row" style={{textAlign:"left"}}>
                      <div class="form-group row">
                      <div class="form-check mx-sm-2">
                        <label class="form-check-label">
                          <input type="checkbox" id="iagree" class="form-check-input"/></label><p style={{marginLeft:"2rem"}}>I hereby confirm that I am above 18 years of age and I agree to the <a href="/termsandconditions">terms and conditions</a> and <a href="/privacy-policy">privacy policy</a> of theStartupConnect. </p>
                      </div>
                      </div>
                      </div>
                    <button class="btn btn-warning" onClick={create_support}>Create Support Ticket</button>
                    </div>
                    </div>
                    </div>
                    
              </div>

              <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
              <img src="https://img.freepik.com/free-vector/flat-customer-support-illustration_23-2148899114.jpg?w=740&t=st=1704878184~exp=1704878784~hmac=aac048b7e8025d619a94365826a6a146f5516651769afa403b354f9dd934fe5a" style={{width:"100%",width:"100%"}}></img>
              </div>

             </div>
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Bt_support;