import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import '../Resources/dashboard.css';
import Navbar from '../navbar';
import axios from '../../axios.js';
import moment from 'moment/moment';

function Bt_contactsales(){

    
  //console.log
 //Checking Sessions
 // Access value associated with the login status
 //const logstatus=sessionStorage.getItem("logStatus00");
 //const logid=sessionStorage.getItem("logId00");
 //const token=sessionStorage.getItem("logToken00");

 

//saving default values with usestate
const state = useState();

//name
const[name,setname]=useState();const updatename = (event) => { setname(event.target.value);}; const[name_er,setname_er]=useState();
//whatsapp
const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);}; const[whatsapp_er,setwhatsapp_er]=useState();
//contact
const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);}; const[contact_er,setcontact_er]=useState();
//email
const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};  const[email_er,setemail_er]=useState();
//module
const[module,setmodule]=useState(sessionStorage.getItem("Smodule00"));const updatemodule = (event) => { setmodule(event.target.value);};  const[module_er,setmodule_er]=useState();
//service
const[service,setservice]=useState(sessionStorage.getItem("Sservice00"));const updateservice = (event) => { setservice(event.target.value);};  const[service_er,setservice_er]=useState();
//preferred_date
const[preferred_date,setpreferred_date]=useState();const updatepreferred_date = (event) => { setpreferred_date(event.target.value);};  const[preferred_date_er,setpreferred_date_er]=useState();
//preferred_time
const[preferred_time,setpreferred_time]=useState();const updatepreferred_time = (event) => { setpreferred_time(event.target.value);};  const[preferred_time_er,setpreferred_time_er]=useState();
//details
const[details,setdetails]=useState();const updatedetails = (event) => { setdetails(event.target.value);};  const[details_er,setdetails_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();



/***************** CONTACT SALES FUNCTION START  ******************/
function contact_sales(){

  //Fetching User Consent Acceptance
  const iagree = document.getElementById('iagree');
  //User Agrees to Terms and Conditions and privacy policy continue to registration
if (iagree.checked) {

//Setting Error Variables
setname_er(" "); setemail_er(" ");setcontact_er(" ");setwhatsapp_er(" ");setmodule_er(" ");setservice_er(" ");setpreferred_date_er(" ");setpreferred_time_er(" ");
seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $name = document.getElementById("name").value; if($name==""){ setname_er("Name is required !");noerror = noerror+1; }//Fetching Name Value
const $email = document.getElementById("email").value; if($email==""){ setemail_er("Email is required !");noerror = noerror+1; }//Fetching Email Value
const $contact = document.getElementById("contact").value; if($contact==""){ setcontact_er("Contact is required !");noerror = noerror+1; }//Fetching contact Value
const $whatsapp = document.getElementById("whatsapp").value; //if($whatsapp==""){ setwhatsapp_er("Whatsapp is required !");noerror = noerror+1; }//Fetching whatsapp Value
const $module = document.getElementById("module").value; if($module==""){ setmodule_er("Module is required !");noerror = noerror+1; }//Fetching module Value
const $service = document.getElementById("service").value; if($service==""){ setservice_er("Service is required !");noerror = noerror+1; }//Fetching service Value
const $details = document.getElementById("details").value; //if($service==""){ setservice_er("Service is required !");noerror = noerror+1; }//Fetching service Value
const $preferred_date = document.getElementById("preferred_date").value; //if($service==""){ setservice_er("Service is required !");noerror = noerror+1; }//Fetching service Value
const $preferred_time = document.getElementById("preferred_time").value; //if($service==""){ setservice_er("Service is required !");noerror = noerror+1; }//Fetching service Value


//No primary errors
if(noerror==0){
//Sending Data through axios
axios.post("bt-contact-sales", { 
Name:$name,
Email:$email,
Contact:$contact,
Whatsapp:$whatsapp,
Module:$module,
Service:$service,
Details:$details,
Preferred_date:moment($preferred_date).format('YYYY-MM-DD'),
Preferred_time:$preferred_time

})

.then((response) => {

//Feedbacks Limit Reached
if(response.data.error==105){     seterror_msg(response.data.error_msg); return; }


//Validation Errors
if(response.data.validatorerror){
setname_er(response.data.validatorerror.Name);
setcontact_er(response.data.validatorerror.Contact);
setwhatsapp_er(response.data.validatorerror.Whatsapp);
setemail_er(response.data.validatorerror.Email);
setmodule_er(response.data.validatorerror.Module);
setservice_er(response.data.validatorerror.Service);
setpreferred_date_er(response.data.validatorerror.Preferred_date);
setpreferred_time_er(response.data.validatorerror.Preferred_time);
setdetails_er(response.data.validatorerror.Details);
}
//Invalid Entries
if(response.data.error==102){ 

setname_er(response.data.name_er); 
setemail_er(response.data.email_er); 
setcontact_er(response.data.contact_er); 
setwhatsapp_er(response.data.whatsapp_er); 
setmodule_er(response.data.module_er); 
setservice_er(response.data.service_er); 
setdetails_er(response.data.details_er); 
setpreferred_date_er(response.data.preferred_date_er); 
setpreferred_time_er(response.data.preferred_time_er); 

}

//Success
if(response.data.successvar==1){


//window.location.replace("/businesstools-startworking");
//setcode(response.data.code);
document.getElementById("success-card").style.display="block";

}
//failure
if(response.data.successvar==2){
seterror_msg(response.data.error_msg);
}
}    );

}

}
//User Not Select the CheckBox
else{
//Show error
seterror_msg("You need to agree to our Terms and Conditions and Privacy Policy to Continue!");
}



}
/***************** CONTACT SALES FUNCTION END  ******************/




return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Startup Connect | Contact Sales</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

  

          <div class="main-panel">
          <div class="d-flex justify-content-center">

              {/* SUCCESS CARD START */}
              <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Thanks for showing your interest for our services !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0">Our Team will soon get in touch with you.</p>
                      <a href="/"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

          <div class="col-12 grid-margin">
          <div class="content-wrapper pb-0">
                <div class="card" style={{textAlign:"left"}}>
                  <div class="card-body">
                    <h4 class="card-title">CONTACT US TO GET BEST FOR YOUR BUSINESS</h4>
                    <div class="form-sample">
                      <p class="card-description">provide your details to continue.</p>
                      <p><code>{error_msg}</code></p>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Name" id="name" value={name} onChange={updatename} />
                              <code>{name_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Email</label>
                            <div class="col-sm-9">
                              <input type="email" class="form-control" placeholder="Enter Email" id="email" value={email} onChange={updateemail} />
                              <code>{email_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Contact</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Contact" id="contact" value={contact} onChange={updatecontact} />
                            <code>{contact_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Whatsapp</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Enter Whatsapp" id="whatsapp" value={whatsapp} onChange={updatewhatsapp} />
                              <code>{whatsapp_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Module</label>
                            <div class="col-sm-9">
                            <select type="email" class="form-control" placeholder="Module" id="module" value={module} onChange={updatemodule} style={{padding:"1rem"}}>
                                <option value="">Select Module</option>
                                <option value="Services">Services</option>
                                <option value="Book Keeping">Book Keeping</option>
                              </select>                 
                            <code>{module_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Service</label>
                            <div class="col-sm-9">
                            <select type="email" class="form-control" placeholder="Service" id="service" value={service} onChange={updateservice} style={{padding:"1rem"}}>
                                <option value="">Select service</option>
                                <option value="NA">NA</option>
                                <option value="Private Limited Registration">Private Limited Registration</option>
                                <option value="Partnership Registration">Partnership Registration</option>
                                <option value="LLP Registration">LLP Registration</option>
                                <option value="Startup India Registration">Startup India Registration</option>
                                <option value="GST Registration">GST Registration</option>
                                <option value="MSME Registration">MSME Registration</option>
                                <option value="Sole Proprietorship Registration">Sole Proprietorship Registration</option>
                                <option value="Public Limited Registration">Public Limited Registration</option>
                                <option value="Static Display Website Development">Static Display Website Development</option>
                                <option value="Web App Development">Web App Development</option>
                                <option value="Ecommerce Website Development">Ecommerce Website Development</option>
                                <option value="Institution Website Development">Institution Website Development</option>
                                <option value="Enterprise Resource Planner Development">Enterprise Resource Planner Development</option>
                                <option value="Customer Relationship Manager Development">Customer Relationship Manager Development</option>
                                <option value="Billing Software Development">Billing Software Development</option>
                                <option value="Point of Sale Development">Point of Sale Development</option>
                                <option value="Inventory Management Software Development">Inventory Management Software Development</option>
                                <option value="Members Management Software Development">Members Management Software Development</option>
                                <option value="SaaS Development">SaaS Development</option>
                                <option value="PaaS Development">PaaS Development</option>
                                <option value="GST Filing">GST Filing</option>
                                <option value="ITR Filing">ITR Filing</option>
                                <option value="CMA Data">CMA Data</option>
                                <option value="Trademark and IPR Registration">Trademark and IPR Registration</option>
                                <option value="Business Plan Creation">Business Plan Creation</option>
                                <option value="Pitch Deck Preparation">Pitch Deck Preparation</option>
                                <option value="Startup Consultancy">Startup Consultancy</option>
                                <option value="SISFS Application">SISFS Application</option>
                                <option value="Project Report Preparation">Project Report Preparation</option>
                                <option value="Valuation">Valuation</option>
                                <option value="Term Sheet Drafting">Term Sheet Drafting</option>
                                <option value="Privacy Policy Drafting">Privacy Policy Drafting</option>
                                <option value="MOU Agreement Drafting">MOU Agreement Drafting</option>
                                <option value="Social Media Management">Social Media Management</option>
                                <option value="Digital Marketing">Digital Marketing</option>
                                <option value="Copywriting">Copywriting</option>
                                <option value="Content Writing">Content Writing</option>
                                <option value="Graphics Designing">Graphics Designing</option>
                                <option value="SEO">SEO</option>
                                <option value="Ecommerce Store Setup">Ecommerce Store Setup</option>
                                <option value="Marketing Campaigns">Marketing Campaigns</option>
                              </select> 
                              <code>{service_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Preferred_date</label>
                            <div class="col-sm-9">
                            <input type="date" class="form-control" placeholder="Enter Date" id="preferred_date" value={preferred_date} onChange={updatepreferred_date} />
                            <code>{preferred_date_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Preferred_time</label>
                            <div class="col-sm-9">
                              <input type="time" class="form-control" placeholder="Enter Time" id="preferred_time" value={preferred_time} onChange={updatepreferred_time} />
                              <code>{preferred_time_er}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Details</label>
                            <div class="col-sm-10">
                              <input type="text" class="form-control" placeholder="Enter details" id="details" value={details} onChange={updatedetails} />
                              <code>{details_er}</code>
                            </div>
                          </div>
                        </div>
                     
                      </div>
                      <div class="row" style={{textAlign:"left"}}>
                      <div class="form-group row">
                      <div class="form-check mx-sm-2">
                        <label class="form-check-label">
                          <input id="iagree" type="checkbox" class="form-check-input"/></label><p style={{marginLeft:"2rem"}}>I hereby confirm that I am above 18 years of age and I agree to the <a href="">terms and conditions</a> and <a href="">privacy policy</a> of theStartupConnect Business tools. </p>
                      </div>
                      </div>
                      </div>
                      <button class="btn-lg btn-success" onClick={contact_sales} >Submit</button>
                   
                    
                    </div>
                  
                  </div>
                </div>
                </div>
              </div>

            
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

//}
}

export default Bt_contactsales;