import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../../axios.js';
import moment from 'moment/moment';


import '../Resources/dashboard.css';
import Insideteam_navbar from './insideteam_navbar.js';
import Insideteam_sidebar from './insideteam_sidebar.js';
//import Create_customer from '../create_customer';

import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';

//EXCEL DOWNLOAD IMPORT
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Insideadmin_createsalescoupon from './Insideadmin_createsalescoupon.js';



function Insideteam_salescoupon(){

  
    //Report Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    
 // Function to download the data as an Excel file
 const downloadExcel = () => {
  // Convert JSON data to Excel worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  
  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  // Convert the workbook to a binary array
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  
  // Create a Blob from the binary data and trigger a download
  const excel_data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(excel_data, "data.xlsx");
};



  //console.log
  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");



{/* CUSTOMERS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14
const[cd_col15_val,setcd_col15_val] = useState(0);//column15
const[cd_col16_val,setcd_col16_val] = useState(0);//column16
const[cd_col17_val,setcd_col17_val] = useState(0);//column17

//Variables
const[data,setData] = useState([]);//SetData Variable

//Error Variable
const[errorvar,seterrorvar] = useState();


//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_coupon_code,setfilter_coupon_code] = useState(); const updatefilter_coupon_code = (event) => { setfilter_coupon_code(event.target.value);}; const[filter_coupon_code_er,setfilter_coupon_code_er]=useState();
const[filter_steam_id,setfilter_steam_id] = useState(); const updatefilter_steam_id = (event) => { setfilter_steam_id(event.target.value);}; const[filter_steam_id_er,setfilter_steam_id_er]=useState();
const[filter_team_id,setfilter_team_id] = useState(); const updatefilter_team_id = (event) => { setfilter_team_id(event.target.value);}; const[filter_team_id_er,setfilter_team_id_er]=useState();
const[filter_username,setfilter_username] = useState(); const updatefilter_username = (event) => { setfilter_username(event.target.value);}; const[filter_username_er,setfilter_username_er]=useState();
const[filter_coupon_status,setfilter_coupon_status] = useState(); const updatefilter_coupon_status = (event) => { setfilter_coupon_status(event.target.value);}; const[filter_coupon_status_er,setfilter_coupon_status_er]=useState();
const[filter_type,setfilter_type] = useState(); const updatefilter_type = (event) => { setfilter_type(event.target.value);}; const[filter_type_er,setfilter_type_er]=useState();
const[filter_category,setfilter_category] = useState(); const updatefilter_category = (event) => { setfilter_category(event.target.value);}; const[filter_category_er,setfilter_category_er]=useState();
const[filter_sub_category,setfilter_sub_category] = useState(); const updatefilter_sub_category = (event) => { setfilter_sub_category(event.target.value);}; const[filter_sub_category_er,setfilter_sub_category_er]=useState();
const[filter_valid,setfilter_valid] = useState(); const updatefilter_valid = (event) => { setfilter_valid(event.target.value);}; const[filter_valid_er,setfilter_valid_er]=useState();

//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);

useEffect(()=>{
   
  getData();
  
  },[])
 
    
  
//******************* GET SALES TEAM DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 

   const $filter_coupon_code = filter_coupon_code;const $filter_steam_id = filter_steam_id; const $filter_team_id = filter_team_id; const $filter_username = filter_username; const $filter_coupon_status = filter_coupon_status; 
   const $filter_type = filter_type; const $filter_category = filter_category; const $filter_sub_category = filter_sub_category; const $filter_valid = filter_valid;

 axios.post("bt-get-inside-salesteam-couponcodes-list", {
   Log_id:logid,
   Token:token,
   Sn_start :0,
   Sn_end :0,
   Sn_total :0,
   Sn_limit :0,
   Filter_val : filter_val,
   Filter_coupon_code : $filter_coupon_code,
   //Filter_steam_id : $filter_steam_id,
   Filter_team_id : $filter_team_id,
  // Filter_username : $filter_username,
   Filter_coupon_status : $filter_coupon_status,
   Filter_type : $filter_type,
   Filter_category : $filter_category,
   Filter_sub_category : $filter_sub_category,
   Filter_status : $filter_coupon_status,
   Filter_valid :  moment($filter_valid).format('YYYY-MM-DD'),
   
   //Code Edits for pagination
   'page' : pageNumber


   })
 .then((response) => {


  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");


     window.location.replace("businesstools-login");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){
    setfilter_coupon_code_er(response.data.validatorerror.Filter_coupon_code);
    setfilter_steam_id_er(response.data.validatorerror.Filter_steam_id);
    setfilter_team_id_er(response.data.validatorerror.Filter_team_id);
    setfilter_username_er(response.data.validatorerror.Filter_username);
    setfilter_coupon_status_er(response.data.validatorerror.Filter_coupon_status);
    setfilter_type_er(response.data.validatorerror.Filter_type);
    setfilter_category_er(response.data.validatorerror.Filter_category);
    setfilter_sub_category_er(response.data.validatorerror.Filter_sub_category);
    setfilter_valid_er(response.data.validatorerror.Filter_valid);


  }

  //Data Violation
  if(response.data.error==102){ 
  
    setfilter_coupon_code_er(response.data.filter_coupon_code_er);
    setfilter_steam_id_er(response.data.filter_steam_id_er);
    setfilter_team_id_er(response.data.filter_team_id_er);
    setfilter_username_er(response.data.filter_username_er);
    setfilter_coupon_status_er(response.data.filter_coupon_status_er);
    setfilter_type_er(response.data.filter_type_er);
    setfilter_category_er(response.data.filter_category_er);
    setfilter_sub_category_er(response.data.filter_sub_category_er);
    setfilter_valid_er(response.data.filter_valid_er);

  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* GET CUSTOMERS DATA FUNCTION END  ********************//


//ecoupon_code
const[ecoupon_code,setecoupon_code]=useState();const updateecoupon_code = (event) => { setecoupon_code(event.target.value);}; const[ecoupon_code_er,setecoupon_code_er]=useState();
//eteam_id
const[eteam_id,seteteam_id]=useState();const updateeteam_id = (event) => { seteteam_id(event.target.value);}; const[eteam_id_er,seteteam_id_er]=useState();
//ecoupon_status
const[ecoupon_status,setecoupon_status]=useState();const updateecoupon_status = (event) => { setecoupon_status(event.target.value);}; const[ecoupon_status_er,setecoupon_status_er]=useState();
//evalid
const[evalid,setevalid]=useState();const updateevalid = (event) => { setevalid(event.target.value);}; const[evalid_er,setevalid_er]=useState();


//ERROR VARIABLES 
const[eerror_msg,seteerror_msg]=useState();
 
 
//******************* EDIT SALES TEAM DEATILS FUNCTION START ********************//
function editsalesteam(){

  const editId=sessionStorage.getItem("EditId00");
  //Setting Error Variables
  setecoupon_code_er(" ");seteteam_id_er(" ");setecoupon_status_er(" ");setevalid_er(" ");seteerror_msg(" ");



  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))){
 
 
  const $ecoupon_code = document.getElementById("ecoupon_code").value;
  const $ecoupon_status = document.getElementById("ecoupon_status").value;
  const $evalid = document.getElementById("evalid").value;
  const $eteam_id = document.getElementById("eteam_id").value;

  axios.post("bt-edit-inside-salescoupon", {
   Log_id:logid,
   Token:token,
   Team_id:$eteam_id,
   Coupon_code:$ecoupon_code,
   Coupon_status : $ecoupon_status,
   Valid:$evalid,
   Edit_id:editId


  })
  .then((response) => {

    //console.log(response);

  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("EditId00");

     window.location.replace("businesstools-login");
  }

   //Validation Errors
 else if(response.data.validatorerror){
  seteteam_id_er(response.data.validatorerror.Team_id);
  setecoupon_code_er(response.data.validatorerror.Coupon_code);
  setecoupon_status_er(response.data.validatorerror.Coupon_status);
  setevalid_er(response.data.validatorerror.Valid);

}
//Invalid Entries
else if(response.data.error==102){ 
  seteteam_id_er(response.data.team_id_er);
  setecoupon_code_er(response.data.coupon_code_er);
  setecoupon_status_er(response.data.coupon_status_er);
  setevalid_er(response.data.valid_er);
 
  seteerror_msg("Cannot Edit Coupon Code!");  }


  //Invalid Entries
  else if(response.data.error==103){     seteerror_msg(response.data.error_msg);  }

   //Bank Updated Successfully
   else if(response.data.successvar==1){        

    document.getElementById("edit-form").style.display="none";  
    setecoupon_code_er(" ");seteteam_id_er(" ");setecoupon_status_er(" ");setevalid_er(" ");seteerror_msg(" ");
    setecoupon_code(" ");seteteam_id(" ");setecoupon_status(" ");setevalid(" ");

    
    document.getElementById("edit-success-card").style.display="block";  }
   //Cannot AUpdate Bank
   else{  seteerror_msg("Cannot Edit Coupon Code!"); }
  

});
}
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");

   window.location.replace("businesstools-login");
 
  }
 
 }
  
 //******************* EDIT COUPON CODE FUNCTION END ********************//




return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>


    <div class="container-scroller">

      <Insideteam_sidebar />
    <div class="container-fluid page-body-wrapper">




       <Insideteam_navbar />
        {/*} partial */}
        <div class="main-panel">      

          <div class="content-wrapper pb-0">

         {/* CREATE COUPON FORM START */}
          <div class="row">
              <div class="col-12 grid-margin create-form" id="create-form">
             <Insideadmin_createsalescoupon />
              </div>
          </div>
          {/* CREATE COUPON FORM END */}
          
            <div class="page-header flex-wrap">
            <div class="header-left"></div>
              <div class="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div class="d-flex align-items-center">
                  <a href="#">
                    <p class="m-0 pe-3">SALES COUPONS</p>
                  </a>
                  <a class="ps-3 me-4" href="#">
                    <p class="m-0"></p>
                  </a>
                </div>
             
              </div>
            </div>

     

            {/* CUSTOMER DETAILS TABLE START */}
                  
             <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Sales Coupon Codes</h4>
                    <p class="card-description">sales team details
                    </p>


                            {/* FILTER SECTION START */}
                            <hr/>
                            <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
                            <div class="d-flex justify-content-between">
                            <div>
                            <h4 style={{padding:'1rem'}}>Filters</h4>
                           </div>
                           <div>
                    
                           <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
                           <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
                           </button>
                           </div>
                           </div>
        
                        <div class="row">
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="KNSIH8987" id="filter_coupon_code" value={filter_coupon_code} onChange={updatefilter_coupon_code}  />
                              <code>{filter_coupon_code_er}</code>
                            </div>
                          </div>
                        </div>
                     {/*}   <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Steam Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_steam_id" value={filter_steam_id} onChange={updatefilter_steam_id}  />
                              <code>{filter_steam_id_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Team Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="121" id="filter_team_id" value={filter_team_id} onChange={updatefilter_team_id}  />
                              <code>{filter_team_id_er}</code>
                            </div>
                          </div>
                        </div>
                      {/*}  <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Username</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="RAKESH123" id="filter_username" value={filter_username} onChange={updatefilter_username}  />
                              <code>{filter_username_er}</code>
                            </div>
                          </div>
                        </div> */}
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Type</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="team" id="filter_type" value={filter_type} onChange={updatefilter_type}  />
                              <code>{filter_type_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="normal" id="filter_category" value={filter_category} onChange={updatefilter_category}  />
                              <code>{filter_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Sub Category</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="regular" id="filter_sub_category" value={filter_sub_category} onChange={updatefilter_sub_category}  />
                              <code>{filter_sub_category_er}</code>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Coupon Status</label>
                            <div class="col-sm-9">
                            <select class="form-control" style={{padding:"1rem"}} placeholder="Offline"  value={filter_coupon_status} onChange={updatefilter_coupon_status}>
                              <option value=" ">all</option>
                              <option value="active">active</option>
                              <option value="inactive">inactive</option>
                              </select>
                              <code>{filter_coupon_status_er}</code>
                            </div>
                          </div>
                        </div>
                    {/*}    <div class="col-md-4">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Valid</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="Rakesh Sharma" value={filter_valid} onChange={updatefilter_valid}  />
                              <code>{filter_valid_er}</code>
                            </div>
                          </div>
                        </div> */}
                   
                        <div class="col-md-4">
                         <button class="btn btn-primary" onClick={()=>{ setfilter_val(1); getData(); }}>Apply Filters</button>
                         <button class="btn btn-danger" style={{marginLeft:"1rem"}} onClick={()=>{ setfilter_val(0); getData(); }}>Remove Filters</button>
                        </div>
                        <br/>
                        <br/>
                      </div>
                            <hr/>                      
                            </div>
                            
                    {/* FILTER SECTION END */}


                  <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                       
                          <div class="dropdown">
                            <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>

                            <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button>     

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Coupon Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Coupon Code</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Steam Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Team Id</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Username</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Type</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Category</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sub Category</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Valid</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Coupon Status</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 12 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date Created</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c12_yes" onClick={()=>{setcd_col12_val(1); document.getElementById("cd_c12_yes").style.display="none"; document.getElementById("cd_c12_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c12_no" style={{ display:"none"}} onClick={()=>{ setcd_col12_val(0); document.getElementById("cd_c12_yes").style.display="block"; document.getElementById("cd_c12_no").style.display="none"; }}></i>
                              </div></div></li> 
                             {/* Column 13 */}
                             <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date Updated</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c13_yes" onClick={()=>{setcd_col13_val(1); document.getElementById("cd_c13_yes").style.display="none"; document.getElementById("cd_c13_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c13_no" style={{ display:"none"}} onClick={()=>{ setcd_col13_val(0); document.getElementById("cd_c13_yes").style.display="block"; document.getElementById("cd_c13_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 14 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c14_yes" onClick={()=>{setcd_col14_val(1); document.getElementById("cd_c14_yes").style.display="none"; document.getElementById("cd_c14_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c14_no" style={{ display:"none"}} onClick={()=>{ setcd_col14_val(0); document.getElementById("cd_c14_yes").style.display="block"; document.getElementById("cd_c14_no").style.display="none"; }}></i>
                              </div></div></li> 
                            
                            
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div class="table-responsive"  id="reports" ref={componentRef}>
                      <table class="table table-hover">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} id="cd_c1" hidden={cd_col1_val==1}>Sn</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c2" hidden={cd_col2_val==1}>Coupon Id</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c3" hidden={cd_col3_val==1}>Coupon Code</th>
                           {/*} <th  style={{fontWeight:"bold"}} id="cd_c4" hidden={cd_col4_val==1}>Steam Id</th> */}
                            <th  style={{fontWeight:"bold"}} id="cd_c5" hidden={cd_col5_val==1}>Team Id</th>
                           {/*} <th  style={{fontWeight:"bold"}} id="cd_c6" hidden={cd_col6_val==1}>Username</th> */}
                            <th  style={{fontWeight:"bold"}} id="cd_c7" hidden={cd_col7_val==1}>Type</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c8" hidden={cd_col8_val==1}>Category</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c9" hidden={cd_col9_val==1}>Sub Category</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c10" hidden={cd_col10_val==1}>Valid</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c11" hidden={cd_col11_val==1}>Coupon Status</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c12" hidden={cd_col12_val==1}>Date Created</th>
                            <th  style={{fontWeight:"bold"}} id="cd_c13" hidden={cd_col13_val==1}>Date Updated</th>
                         
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

            
                         return(
                         <>
                       
                        <tr key={curElm.Sn}>
                            <td id="cd_co1"  hidden={cd_col1_val==1}>{curElm.Sn}</td>
                            <td id="cd_co2"  hidden={cd_col2_val==1}>{curElm.Coupon_id}</td>
                            <td id="cd_co3"  hidden={cd_col3_val==1}>{curElm.Coupon_code}</td>
                          {/*}  <td id="cd_co4"  hidden={cd_col4_val==1}>{curElm.Steam_id}</td> */}
                            <td id="cd_co5"  hidden={cd_col5_val==1}>{curElm.Team_id}</td>
                          {/*}  <td id="cd_co6"  hidden={cd_col6_val==1}>{curElm.Username}</td> */}
                            <td id="cd_co7"  hidden={cd_col7_val==1}>{curElm.Type}</td>
                            <td id="cd_co8"  hidden={cd_col8_val==1}>{curElm.Category}</td>
                            <td id="cd_co9"  hidden={cd_col9_val==1}>{curElm.Sub_category}</td>
                            <td id="cd_co10"  hidden={cd_col10_val==1}>{curElm.Valid}</td>
                            <td id="cd_co11"  hidden={cd_col11_val==1}>
                            <label className="badge badge-danger" hidden={curElm.Coupon_status=="active"}>inactive</label>
                            <label className="badge badge-success" hidden={curElm.Coupon_status=="inactive"}>active</label>
                            </td>

                            <td id="cd_co12"  hidden={cd_col12_val==1}>{curElm.Date_created}</td>
                            <td id="cd_co13"  hidden={cd_col13_val==1}>{curElm.Date_updated}</td>
                           
                           </tr>
                          </>
                         )
                         }
					   
	                  		 )
                         }
                       
                         
                        </tbody>
                      </table>
                    </div>
                        {/* PAGINATION SECTION START */}
                        <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}

                    <button class="btn btn-light" onClick={handlePrint}><i class="mdi mdi-cloud-print text-dark"></i></button>
                    <button class="btn btn-light" onClick={downloadExcel}><i class="mdi mdi-file-excel text-dark"></i></button>

                   
                   
                  </div>
                </div>
              </div>
              {/* CUSTOMER DETAILS TABLE END */}

           {/*}   <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Customer Summary</h4>
                    <p class="card-description">customer summary
                    </p>
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}}>Sn</th>
                            <th  style={{fontWeight:"bold"}}>Customer Id</th>
                            <th  style={{fontWeight:"bold"}}>Customer Name</th>
                            <th  style={{fontWeight:"bold"}}>Sales</th>
                            <th  style={{fontWeight:"bold"}}>This month</th>
                            <th  style={{fontWeight:"bold"}}>Last Transaction</th>
                          </tr>
                        </thead>
                        <tbody>
                       
                        <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                           <tr>
                            <td>24</td>
                            <td>CA98777</td>
                            <td>Sumit Mukherjee</td>
                            <td>330000.00</td>
                            <td class="text-success">21.00 % <i class="mdi mdi-arrow-up"></i></td>
                            <td>21-08-2023</td>
                          </tr>
                          
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>*/}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
         {/*} <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);


//}
}
export default Insideteam_salescoupon;