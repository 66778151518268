import React from 'react';
import {useState,useEffect,Component} from 'react';
//import axios from './axios';
import './footer1css.css';
import axios from '../axios.js';


function Footer1(){

  //fcname
const[fcname,setfcname]=useState();const updatefcname = (event) => { setfcname(event.target.value);}; const[fcname_er,setfcname_er]=useState();
//fccontact
const[fccontact,setfccontact]=useState();const updatefccontact = (event) => { setfccontact(event.target.value);}; const[fccontact_er,setfccontact_er]=useState();
//fcemail
const[fcemail,setfcemail]=useState();const updatefcemail = (event) => { setfcemail(event.target.value);}; const[fcemail_er,setfcemail_er]=useState();
//fcmessage
const[fcmessage,setfcmessage]=useState();const updatefcmessage = (event) => { setfcmessage(event.target.value);}; const[fcmessage_er,setfcmessage_er]=useState();
const[error_msg,seterror_msg]=useState();//error_msg
const[success_msg,setsuccess_msg]=useState();//success_msg


/***************** CONTACT US FUNCTION START  ******************/
function contactus(){



//Setting Error Variables
setfcname_er(" "); setfcemail_er(" ");setfccontact_er(" ");setfcmessage_er(" ");seterror_msg(" ");


//Fetching Form Data
let noerror = 0;
const $fcname = document.getElementById("fcname").value; if($fcname==""){ setfcname_er("Name is required !");noerror = noerror+1; }//Fetching fcname Value
const $fcemail = document.getElementById("fcemail").value; if($fcemail==""){ setfcemail_er("Email is required !");noerror = noerror+1; }//Fetching fcEmail Value
const $fccontact = document.getElementById("fccontact").value; if($fccontact==""){ setfccontact_er("Contact is required !");noerror = noerror+1; }//Fetching fccontact Value
const $fcmessage = document.getElementById("fcmessage").value; if($fcmessage==""){ setfcmessage_er("Message is required !");noerror = noerror+1; }//Fetching fcmessage Value


//No primary errors
if(noerror==0){


//Sending Data through axios
axios.post("contact-us", { 
Name:$fcname,
Email:$fcemail,
Contact:$fccontact,
Message:$fcmessage,

})

.then((response) => {

//Validation Errors
if(response.data.validatorerror){
setfcname_er(response.data.validatorerror.Name);
setfccontact_er(response.data.validatorerror.Contact);
setfcemail_er(response.data.validatorerror.Email);
setfcmessage_er(response.data.validatorerror.Message);
seterror_msg("Cannot Send Message !");


}
//Invalid Entries
if(response.data.error==102){ 

setfcname_er(response.data.name_er);
setfccontact_er(response.data.contact_er);
setfcemail_er(response.data.email_er);
setfcmessage_er(response.data.message_er);
seterror_msg("Cannot Send Message !");


}

//Success
if(response.data.successvar==1){

//window.location.replace("/businesstools-startworking");
//document.getElementById("success-card").style.display="block";
setsuccess_msg("Message Sent to our Team. They will soon get back to you !!!");
setfcname("");setfccontact("");setfcemail("");setfcmessage("");

}
//failure
if(response.data.successvar==2){
seterror_msg("Cannot Send Message!");
}
}    );

}




}
/***************** CONTACT US FUNCTION END  ******************/


return(
<>
<div class="home2-footer">
    <div class="home2-footer-container">
        
        <div class="home2-footer-section1">
        <div class="home2-footer-list-section">
        <div class="home2-footer-section1-list">
         <h3>Portals</h3>
         <ul>
            <li>Services</li>
            <li>Tools</li>
            <li>Network</li>
            <li>Hiring</li>
            <li>Contests</li>
            <li>Trainings</li>
            <li>Careers</li>
         </ul>
        </div>
        <div class="home2-footer-section1-list">
        <h3>Links</h3>
        <ul>
            <li>Terms and Conditions</li>
            <li>Privacy Policy</li>
            <li>Refund and Cancellation</li>
            <li>Disclaimer</li>
         </ul>
        </div>
        </div>

        <div class="col-md-12">
        <div class="standard_social_links">
        <div class="home2-footer-sociallinks-bar">
        <ul>
        <li><a href="https://www.facebook.com/profile.php?id=100089210907604"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/5968/5968764.png" id="footer-sm-icons"/></a></li>
        <li><a href="https://www.instagram.com/thestartupconnect/"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3955/3955024.png" id="footer-sm-icons"/></a></li>
        <li><a href="https://www.linkedin.com/company/startupconnect1/"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/145/145807.png" id="footer-sm-icons"/></a></li>
        <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3670/3670147.png" id="footer-sm-icons"/></a></li>
        <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/3670/3670127.png" id="footer-sm-icons"/></a></li>
        <li><a href="#"><img class="round-btn btn-facebook" src="https://cdn-icons-png.flaticon.com/512/2913/2913972.png" id="footer-sm-icons"/></a></li>
        </ul>
        </div>
        <a className="navbar-brand" href="#"><img style={{width:"100px",height:"100px"}} src="/images/logo-img.png" /><span style={{color:"#ffffff",fontWeight:"bold"}}>Startup</span><span style={{color:"#ffffff",fontWeight:"bold"}}> Connect</span></a>


        </div>  

      
        </div>
       

        </div>
      
   

        
        <div class="home2-footer-section1">
         
         <div class="home2-footer-contact-form">
            <h2>Contact Form</h2>
           <p style={{color:"#50ff9d"}}>{success_msg}</p>
           <p style={{color:"#efc2c2"}}>{error_msg}</p>
            <div>
  <div class="form-group" style={{marginTop:"1rem"}}>
    <label for="exampleInputEmail1">Name</label>
    <input type="text" class="form-control" id="fcname" value={fcname} onChange={fcname} placeholder="Enter Name" />
    <code style={{color:"#efc2c2"}}>{fcname_er}</code>
  </div>
  <div class="form-group"  style={{marginTop:"1rem"}}>
    <label for="exampleInputPassword1">Email</label>
    <input type="email" class="form-control" id="fcemail" value={fcemail} onChange={fcemail} placeholder="myemail@gmail.com" />
    <code style={{color:"#efc2c2"}}>{fcemail_er}</code>
  </div>
  <div class="form-group"  style={{marginTop:"1rem"}}>
    <label for="exampleInputPassword1">Contact</label>
    <input type="text" class="form-control" id="fccontact" value={fccontact} onChange={fccontact} placeholder="+91 9000000000" />
    <code style={{color:"#efc2c2"}}>{fccontact_er}</code>
  </div>
  <div class="form-group"  style={{marginTop:"1rem"}}>
    <label for="exampleInputPassword1">Ask your question</label>
    <textarea class="form-control" id="fcmessage" value={fcmessage} onChange={fcmessage}></textarea>
    <code style={{color:"#efc2c2"}}>{fcmessage_er}</code>
  </div>
  <div class="form-check"  style={{marginTop:"1rem"}}>
    <input type="checkbox" class="form-check-input" id="exampleCheck1" />
    <label class="form-check-label" for="exampleCheck1">I agree to <a href="">Terms and Conditions</a> and <a href="">Privacy Policy</a> of Stratup Connect</label>
  </div>
  <button type="submit" class="btn btn-primary mt-2" style={{backgroundColor:"#fbdd1d",color:"#000000",fontWeight:"bold",borderColor:"#ffffff"}} onClick={contactus}>SUBMIT</button>
  </div>

         </div>
        </div>
    </div>

    <div class="home2-footer-down" >
    <p>Copyright ⓒ 2023 | All Rights Reserved by Technomate Startup Services Private Limited.</p>
    </div>
</div>   
</>
);


}


export default Footer1;