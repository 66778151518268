import { toHaveErrorMessage } from '@testing-library/jest-dom/dist/matchers';
import React, { useState, useEffect } from 'react';
import { scryRenderedDOMComponentsWithTag } from 'react-dom/test-utils';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
//import Navbar from './navbar';
import axios from '../../axios.js';
//import useScript from '../hooks/useScript';
{/*}
const MyComponent = props => {
  useScript('https://checkout.razorpay.com/v1/checkout.js');

  // rest of your component
}*/}
//import { Link } from "react-router-dom";
//import {Helmet} from "react-helmet";
//Checking Sessions




    // Access value associated with the PRICING PLAN
    const priceplan=sessionStorage.getItem("pricingplan00");
    const planname=sessionStorage.getItem("planname00");
    const logstatus=sessionStorage.getItem("logStatus00");
    const logid=sessionStorage.getItem("logId00");
    const token=sessionStorage.getItem("logToken00");


function Bt_pricing() {



  //Importing Script
  const useScript = url => {
    useEffect(() => {
      const script = document.createElement('script');

      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
  };
    //loadcount
    const [loadcount, setloadcount] = useState(0);
  //message
  const [message, setmessage] = useState();
  //details
  const [details, setdetails] = useState();
  //planvalue
  const [planvalue, setplanvalue] = useState(1);
  //transactions
  const [transactions, settransactions] = useState();
  //businesses
  const [businesses, setbusinesses] = useState();
  //team
  const [team, setteam] = useState();
  //productlist
  const [productlist, setproductlist] = useState();
  //customers
  const [customers, setcustomers] = useState();
  //suppliers
  const [suppliers, setsuppliers] = useState();
  //days
  const [days, setdays] = useState();
  //orderid
  const [orderid, setorderid] = useState();
  //Error Messages
  //usernamemsg
  const [usernamemsg, setusernamemsg] = useState();
  //namemsg
  const [namemsg, setnamemsg] = useState();
  //couponcodemsg
  const [couponcodemsg, setcouponcodemsg] = useState();
  //daysmsg
  const [daysmsg, setdaysmsg] = useState();
  //amountmsg
  const [amountmsg, setamountmsg] = useState();
  //username
  //const [username, setusername] = useState(User); const updateusername = (event) => { setusername(event.target.value); };
  //name
  const [name, setname] = useState(); const updatename = (event) => { setname(event.target.value); };
  //duration
  const [duration, setduration] = useState(); const updateduration = (event) => { setduration(event.target.value); defaultload(); setcouponapplycount(0);setcouponcodemsg(); };
  //couponcode
  const [couponcode, setcouponcode] = useState(); const updatecouponcode = (event) => { setcouponcode(event.target.value);   };
  //amount
  const [amount, setamount] = useState(500);//const updateamount = (event) => {  setamount(event.target.value); };
  //couponapplycount
  const [couponapplycount, setcouponapplycount] = useState(0);


    
function defaultload(){
  // alert(logtype);
 
  /* if((logstatus==null)||(User==null)||((logtype==null)||(logtype=="user"))){
 
     alert("Please Login as Admin to Continue !");
     window.location.replace("/");
     
   }
   else*/{
 
    //
    if(priceplan!=null){
        
    
       //fetching login api
       axios.post("bt-get-pricingdetail", {
         "Plancode": priceplan,
       //  "User":User,
       //  "Logtype":logtype,
         "Log_id":logid,
         "Token":token,
         "Logstatus":logstatus
       })
         .then((response) => {
         
           const svar = response.data.successvar;
           const errorvar = response.data.errorvar;
      
          if(svar==1){
            
            const s_businesses =(response.data.businesses);setbusinesses(response.data.businesses);
            const s_team =(response.data.team);setteam(response.data.team);
            const s_customers =(response.data.customers);setcustomers(response.data.customers);
            const s_suppliers =(response.data.suppliers);setsuppliers(response.data.suppliers);
            const s_productlist =(response.data.productlist);setproductlist(response.data.productlist);
            const s_transactions =(response.data.transactions);//settransactions(response.data.transactions);
            const s_days =(response.data.days);//setdays(response.data.days);
            const s_amount =(response.data.amount);//setamount(response.data.amount);

            //First time load
            if(loadcount==0){

   
              const newtransactions = s_transactions;
              const newdays = s_days;
              const newamount = s_amount;
              settransactions(newtransactions);
              setdays(newdays);
              setamount(newamount);
              setloadcount(1);
              setduration(newdays+"days");
            }
            //other loads
            else{
              //fetching duration
              const durchange = document.getElementById("duration").value;
    //Plan Changes
    if (durchange == "28 days") {

   
      const newtransactions = s_transactions;
      const newdays = s_days;
      const newamount = s_amount;
      settransactions(newtransactions);
      setdays(newdays);
      setamount(newamount)

    }
    if (durchange == "56 days") {

      const newtransactions = s_transactions*2;
      const newdays = s_days*2;
      const newamount = s_amount*2;
      settransactions(newtransactions);
      setdays(newdays);
      setamount(newamount)
    }
    if (durchange == "84 days") {
      const newtransactions = s_transactions*3;
      const newdays = s_days*3;
      const newamount = s_amount*3;
      settransactions(newtransactions);
      setdays(newdays);
      setamount(newamount)
    }
  }

          }
             
          else{
          //invalid plan code
        /*  alert("Please Select Pricing Plan to Continue !");
          window.location.replace("/home#pricing"); */
          
          }
         });
 }
 else{
   //Pricing Plan not Selected
  /* alert("Please Select Pricing Plan to Continue !");
   window.location.replace("/home#pricing"); */
   //
 }
}
 }
 
   
     
   useEffect(()=>{
     
     defaultload();
   
   },[])

   



  //VALIDATE COUPON CODE
  function validatecoupon() {

 setcouponcodemsg();
    if (((couponcode.length) > 3)&&(couponapplycount==0)) {

      //fetching login api
      axios.post("bt-check-couponcode", {
        "Coupon": document.getElementById("couponcode").value
      })
        .then((response) => {
            

          const errorvar = response.data.errorvar;
          const svar = response.data.successvar;


          //success
          if (svar == 1) {

            const coupon_transactions = response.data.coupon_transactions;
            const coupon_team = response.data.coupon_team;
            const coupon_business = response.data.coupon_business;
            const coupon_customers = response.data.coupon_customers;
            const coupon_suppliers = response.data.coupon_suppliers;
            const coupon_productlist = response.data.coupon_productlist;
            const coupon_discountper = response.data.coupon_discountper;
            const coupon_discountamt = response.data.coupon_discountamt;
            const coupon_days = response.data.coupon_days;

            if (coupon_discountper != null) { const discountamt = amount * coupon_discountper / 100; const newamount = amount - discountamt; setamount(newamount); }
            if (coupon_transactions != null) { const extratrans = transactions + coupon_transactions; settransactions(extratrans); }
            setcouponcodemsg("Coupon Code Applied !");
            document.getElementById("coupon-msg").style.color="green";
            setcouponapplycount(1);
          }
          else {
            setcouponcodemsg("Invalid Coupon Code !");

          }

        });
    }
  }

  //PAYMENT CREATE ORDER
  function createorder() {

    setnamemsg();
    setusernamemsg();
    setmessage();
    setdaysmsg();
    setamountmsg();

    //fetching login api
    axios.post("bt-start-payment", {
      "Coupon": couponcode,
     // "Username": username,
      "Name": name,
      "Days": 28,//duration
      "Amount": "500",//amount
      "Plancode": "Test123",
      "Log_id":logid,
      "Token":token
    })
      .then((response) => {
        

        const errorvar = response.data.errorvar;
        const svar = response.data.successvar;


    
        //success
        if (svar == 1) {
          setorderid(response.data.codeId);
          document.getElementById("paynow").style.display = "block";
          document.getElementById("confirmorder").style.display = "none";
        
        }
        if (svar == 2) {
          //Validation Errors
          setnamemsg(response.data.validatorerror.Name);
          setusernamemsg(response.data.validatorerror.Username);
          setmessage(response.data.validatorerror.Plancode);
          setdaysmsg(response.data.validatorerror.Days);
          setamountmsg(response.data.validatorerror.Amount);
        }
        if (errorvar == 4) {
          setusernamemsg("Invalid Username !");
        }
        if (errorvar == 1) {
          setcouponcodemsg("Invalid Coupon Code !");
        }
        if (errorvar == 2) {
          setmessage("Cannot Generate Message Id ! Try Again !");
        }
        if (errorvar == 3) {
          //Data Violation Errors
          setnamemsg(response.data.name_error);
          setusernamemsg(response.data.username_error);
          setmessage(response.data.plancode_error);
          setdaysmsg(response.data.days_error);
          setamountmsg(response.data.amount_error);
        }
      });
  }

  //PAY USING RAZORPAY
  function pay() {
    setnamemsg();
    setusernamemsg();
    setmessage();
    setdaysmsg();
    setamountmsg();

    //fetching login api
    axios.post("https://checkout.razorpay.com/v1/checkout.js", {
      "data-key": "rzp_test_YU5AJDf0ay9pxz",
      "data-amount": "2000",
      "data-currency": "INR",
      "data-order_id": orderid,
      "data-buttontext": "Pay with Razorpay",
      "data-name": "Basic",
      "data-description": "test transaction",
      "data-theme.color": "#28bced"
    })
      .then((response) => {

        const errorvar = response.data.errorvar;
        const svar = response.data.successvar;


        //success
        if (svar == 1) {
          document.getElementById("paynow").style.display = "block";
          document.getElementById("confirmorder").style.display = "none";
        }

      });
  }

  //Function to Load Script
  function loadScript(src) {
      return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
              resolve(true);
          };
          script.onerror = () => {
              resolve(false);
          };
          document.body.appendChild(script);
      });
  }

  async function displayRazorpay() {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    // const result = await axios.post("http://localhost:8000/success/orders");

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }

    // const { amount, id: order_id } = result.data;


  //set dummy amount
  //const damt = 500;

    const options = {
        key: "rzp_test_YU5AJDf0ay9pxz", // Enter the Key ID generated from the Dashboard
        amount:"500",//amount.toString(),//amount.toString(),
        currency: 'INR',
        name: "Soumya Corp.",
        description: "Test Transaction",
        order_id: orderid,
        handler: async function (response) {
            const data = {
                orderCreationId: orderid,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
           // alert(data.orderCreationId);

             const result = await axios.post("http://127.0.0.1:8000/api/bt-complete-payment",{   orderCreationId: orderid,
             razorpayPaymentId: response.razorpay_payment_id,
             razorpayOrderId: response.razorpay_order_id,
             razorpaySignature: response.razorpay_signature} /*data*/) .then((response) => {
             

              const svar = response.data.successvar;
              
              const valueofsuccess = response.data.valueofsuccess;
           
              if((svar==1)&&(valueofsuccess==true)){
                alert("Congratulations ! Your Transactions has been Updated !");
                window.location.replace("/businesshome");
              }
              else{
                alert("Cannot Complete Transaction ! Do contact us through contact section for any queries regarding your transaction !");
              }
          
              //removing priceplan session
              sessionStorage.removeItem("pricingplan00");
          });
        },
     /*   prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        */
        theme: {
            color: "#61dafb",
        },
      };
    

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}


  return (
    <>
    

    
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Business Tools|BookKeeping Solution</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div class="container-scroller">


    <div class="container-fluid page-body-wrapper">


      
        {/*} partial */}

      <div className="text-center">
        <div className="col-12 grid-margin stretch-card">
          <div className="card" style={{ marginTop: "5rem", alignItems: "center" }}>
            <div className="box featured">
              <h3 style={{ backgroundColor: "#0593ac", padding: "2rem", width: "100%" }}>{planname}</h3>
              <h5>Your Plan Details</h5>
              <h4>
                <sup>₹</sup>{amount}<span> for {days} days</span>
              </h4>
              <h5>Your Plan Includes</h5>
              <ul>
                <li>{transactions} transactions for {days} days</li>
                <li>{businesses} Business</li>
                <li>Add upto {team} Team Members</li>
                <li>{customers} Customers storage in Customers List</li>
                <li>{suppliers} Suppliers storage in Suppliers List</li>
                <li>{productlist} Products Storage in Products List</li>
                <li>Create and View Reports</li>
                <li>Share reports and Invoices with share ID</li>
                <li>24/7 customer support</li>
              </ul>
              <div className="btn-wrap">
                <div className="pt-3">
             {/*     <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="exampleInputUser1" placeholder="Username" value={username}  />
                    <code>{usernamemsg}</code>
                  </div> */}
                  <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="exampleInputUser1" placeholder="Name" value={name} onChange={updatename} />
                    <code>{namemsg}</code>
                  </div>
                  <div className="form-group">
                    <select type="text" className="form-control form-control-lg" style={{ color: "black" }} id="duration" placeholder="Duration" value={duration} onChange={updateduration} >
                      <option value="28 days">28 days</option>
                      <option value="56 days">56 days</option>
                      <option value="84 days">84 days</option>
                    </select>
                    <code></code>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <input type="text" class="form-control"  id="couponcode" placeholder="Coupon Code" value={couponcode} onChange={updatecouponcode} aria-label="Recipient's username" />
                      <div class="input-group-append">
                        <button class="btn btn-sm btn-primary" type="button" onClick={validatecoupon}>Apply</button>
                      </div>
                    </div>
                    <code id="coupon-msg">{couponcodemsg}</code>
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control form-control-lg" id="amount" placeholder="Amount Payable" contentEditable="false" value={amount} />
                    <div className="mt-3">
                      <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" id="confirmorder" onClick={createorder} >Confirm Order</a>
                      <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={displayRazorpay} id="paynow" style={{display:"none", backgroundColor: "#2b7a87" }}>Proceed to Pay</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

  );

}

export default Bt_pricing;